<template>
    <div class="menu-nav">
        <div class="con">
            <div class="left">
                <div
                    class="home item"
                    :class="{active: isActive(home)}"
                    @click="navClick(home)"
                >
                    <div class="tag">
                        <div
                            class="icon"
                            :style="{backgroundImage: `url(${home.icon[1]})`}"
                        >
                            <img :src="home.icon[0]" />
                        </div>
                        <div class="text">
                            {{home.title}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="right">
                <div
                    class="col"
                    v-for="(num, index) in numEachRow"
                    :key="num"
                >
                    <div
                        class="item"
                        :class="{active: isActive(data[index])}"
                        @click="navClick(data[index])"
                    >
                        <div
                            class="tag"
                            :class="{hot: data[index].hot}"
                        >
                            <div
                                class="icon"
                                :style="{backgroundImage: `url(${data[index].icon[1]})`}"
                            >
                                <img :src="data[index].icon[0]" />
                            </div>
                            <div class="text">
                                {{data[index].title}}
                            </div>

                            <div v-if="data[index].hot" class="badge">
                                hot
                            </div>
                        </div>
                    </div>

                    <div
                        class="item"
                        :class="{active: isActive(data[index + numEachRow])}"
                        @click="navClick(data[index + numEachRow])"
                    >
                        <div
                            class="tag"
                            :class="{hot: data[index + numEachRow].hot}"
                        >
                            <div
                                class="icon"
                                :style="{backgroundImage: `url(${data[index + numEachRow].icon[1]})`}"
                            >
                                <img :src="data[index + numEachRow].icon[0]" />
                            </div>
                            <div class="text">
                                {{data[index + numEachRow].title}}
                            </div>

                            <div v-if="data[index + numEachRow].hot" class="badge">
                                hot
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal-poster ref="poster"></modal-poster>
    </div>
</template>

<script>
import ModalPoster from '@/components/sys/modal-poster.vue';

export default {
    components: {
        ModalPoster,
    },
    props: {
        // data: {
        //     type: Array,
        //     default: () => [],
        // },
    },
    data() {
        return {
            home: {
                title: '首页',
                link: '/home',
                icon: [
                    '/images/nav/icon-shouye.png',
                    '/images/nav/icon-shouye1.png',
                ],
                active: /^(\/home)$/,
            },
            data: [
                {
                    title: '全国ca代办',
                    link: '/application/ca-agent',
                    open: true,
                    icon: [
                        '/images/nav/icon-ca.png',
                        '/images/nav/icon-ca1.png',
                    ],
                },
                {
                    title: '标书制作',
                    link: '/application/file-agent',
                    open: true,
                    icon: [
                        '/images/nav/icon-biaoshu.png',
                        '/images/nav/icon-biaoshu1.png',
                    ],
                    hot: true,
                },
                {
                    // title: '金融服务',
                    title: '保函办理',
                    link: '',
                    icon: [
                        '/images/nav/icon-jinrong.png',
                        '/images/nav/icon-jinrong1.png',
                    ],
                    onclick: () => {
                        // this.$refs.poster.open({
                        //     src: '/images/home/poster/finance-serve.png',
                        //     name: '金融服务',
                        //     color: ['#922304', '#ffa5a5', '#ffffff'],
                        // });

                        window.open('http://www.bhtong.cn');
                    },
                },
                {
                    title: '电子投标服务',
                    link: '/application/electronic-bidding',
                    open: true,
                    icon: [
                        '/images/nav/icon-fuwu.png',
                        '/images/nav/icon-fuwu1.png',
                    ],
                },
                {
                    title: '标书检查',
                    link: '/application/file-check',
                    open: true,
                    icon: [
                        '/images/nav/icon-biaoshu.png',
                        '/images/nav/icon-biaoshu1.png',
                    ],
                },

                {
                    title: '文件对比工具',
                    link: '/application/file-diff',
                    open: true,
                    icon: [
                        '/images/nav/icon-file-diff.png',
                        '/images/nav/icon-file-diff1.png',
                    ],
                    hot: true,
                },
                {
                    title: '监控对手/业主',
                    link: '/home?recommend_type=business',
                    open: true,
                    icon: [
                        '/images/nav/icon-jiankong.png',
                        '/images/nav/icon-jiankong1.png',
                    ],
                },
                {
                    title: '投标方案库',
                    link: '/document',
                    open: true,
                    icon: [
                        '/images/nav/icon-fangan.png',
                        '/images/nav/icon-fangan1.png',
                    ],
                },
                {
                    title: '投标方案制作',
                    link: '/application/biddingfiletool',
                    open: true,
                    icon: [
                        '/images/nav/icon-zhizuo.png',
                        '/images/nav/icon-zhizuo1.png',
                    ],
                },
                {
                    title: '数据报告',
                    link: '/tools/data-export',
                    open: true,
                    icon: [
                        '/images/nav/icon-shuju.png',
                        '/images/nav/icon-shuju1.png',
                    ],
                },
            ],
        };
    },
    computed: {
        numEachRow() {
            const ROWS = 2;

            return Math.ceil(this.data.length / ROWS);
        },
    },
    methods: {
        /**
         * 子导航项被点击
         */
        navClick(item, parent) {
            var { link, open, onclick } = item;

            this.setCzc([
                '导航栏',
                '点击按钮',
                item.title,
                0,
                `nav-btn-${item.title}`,
            ]);

            if (onclick) {
                // console.log(onclick)
                onclick(item, parent);
                return;
            }

            if (!link) {
                showMsgBox('敬请期待');

                return;
            }

            var hasQuery = /\?/.test(link),
                channel = this.getQuery('channel'),
                newLink = link;

            if (channel) {
                newLink = hasQuery ? `${link}&channel=${channel}` : `${link}?channel=${channel}`;
            }

            if (open) {
                window.open(newLink);
                return;
            }

            this.goto(newLink);
        },
        // 当前激活中
        isActive: function (item) {
            var { link, title, active } = item;

            if (!link) {
                return false;
            }

            if (this.active) {
                // 通过参数判断
                return this.active === title;
            }

            // 通过地址判断
            var { fullPath, path } = this.$route,
                fullPath = decodeURIComponent(fullPath);

            if (active) {
                // 有正则，则使用item下的正则
                return active.test(fullPath);
            }

            if (/\?/.test(link)) {
                // 带参数则全匹配
                return fullPath === link;
            }

            var reg = new RegExp(link);

            return path === link && reg.test(fullPath);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

.menu-nav {
    font-size: 14px;
    color: white;
    margin-right: 10px;

    .con {
        display: flex;
        align-items: center;

        .left {
            margin-right: 20px;
        }

        .right {
            flex: 1;
            display: flex;

            .col {
                display: flex;
                flex-direction: column;

                & + .col {
                    margin-left: 14px;
                }
            }
        }
    }

    .item {
        color: white;
        display: flex;
        line-height: 1em;
        cursor: pointer;
        padding: 2px 0;
        transition: all 0.3s;

        .tag {
            display: flex;
            align-items: center;
            padding: 6px 8px;
            line-height: 1em;
            border-radius: 3px;
            position: relative;

            &.hot {
                background: linear-gradient(
                    to top,
                    $primaryColor8,
                    $primaryColor9
                );

                .text {
                    font-weight: bold;
                    font-style: italic;
                }
            }

            &:hover {
                background: linear-gradient(
                    to top,
                    $primaryColor7,
                    $primaryColor8
                );
            }

            .icon {
                display: block;
                width: 18px;
                height: 18px;
                margin-right: 6px;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            .text {
                flex: 1;
            }

            .badge {
                background: $dangerColor;
                color: white;
                position: absolute;
                right: -16px;
                top: -6px;
                font-size: 12px;
                line-height: 1em;
                padding: 3px 6px;
                border-radius: 10px;
                border: 1px solid rgba(255, 255, 255, .5);
            }
        }

        &.active {
            .tag {
                background: white;
                color: $primaryColor;

                .icon {
                    img {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1310px) {
    .menu-nav {
        margin-right: 8px;

        .left {
            margin-right: 6px !important;
        }

        .right {
            .col {
                & + .col {
                    margin-left: 0 !important;
                }
            }
        }

        .item {
            padding: 0;

            .tag {
                padding-left: 4px;
                padding-right: 4px;
            }
        }
    }
}
</style>