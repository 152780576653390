<template>
    <div class="info-form mybr">
        <my-form v-model="form" :readonly="readonly" autocomplete="off" label-width="150px" @submit="submitHandler">

            <el-form-item>
                <label slot="label"> <span style="font-weight:600; color:#333">获取信息设置</span></label>
                信息勾选越精确服务内容越精细
            </el-form-item>

            <el-form-item prop="prefer" label="更关注的信息：" :rules="readonly ? [] : newRule('关注内容', 'required')" >
                <el-radio-group v-model="form.prefer" @change="getSystemType" :class="[{'re-color':readonly}]">
                    <el-radio label="bid">招标项目信息</el-radio>
                    <el-radio label="zb">中标项目信息（99%的分包单位选择了该项）</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item prop="messageType" label="勾选信息类型：" :rules="readonly ? [] : [{ validator: checkType, trigger: 'blur'},'required']">
                <el-checkbox-group v-model="messageType" @change="typeChange"  :class="[{'re-color':readonly}]">
                    <el-checkbox v-for="(item) in messageTypeOptions" :key="item.id" :label="item.valueid">{{item.valuename}}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item v-if="form.prefer != 'zb'" prop="messageFenlei" label="勾选信息分类：" :rules="readonly ? [] : [{ validator: checkFenlei, trigger: 'blur' },'required']">
                <el-checkbox-group v-model="messageFenlei" @change="fenleiChange" :class="[{'re-color':readonly}]">
                    <el-checkbox v-for="(item) in messageFenleiOptions" :key="item.id" :label="item.valueid">{{item.valuename}}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item prop="customwords" label="可提供的服务：" :rules="readonly ? [] : [{ validator: inputCustom, trigger: 'blur' },'required']">
                <div v-if="!readonly">
                    <el-input v-model="form.customwords" clearable autocomplete="off" @input="changeInput($event)" :placeholder="`比如您可提供保洁服务，即输入保洁`">
                    </el-input>
                </div>
                <div v-if="readonly">
                    <div class="BgInput">{{form.customwords}} &nbsp;</div>
                    <div class="BgLine"></div>
                </div>
                <span style="color: #999;">最多可设置五个词，每个词最多输入2-20字，用空格隔开</span>
            </el-form-item>

            <el-form-item>
                <label slot="label"> <span style="font-weight:600; color:#333">个人信息补充</span></label>
                单位名称越详细平台服务越精准
            </el-form-item>

            <el-form-item prop="dwname" label="公司名称：" :rules="readonly ? [] : newRule('公司名称', 'required', 'max50')">
                <el-input v-if="!readonly" v-model="form.dwname" clearable autocomplete="off" :placeholder="`请输入公司名称`"></el-input>
                <div v-if="readonly" class="BgInput">{{form.dwname}}</div>
                <div v-if="readonly" class="BgLine"></div>
            </el-form-item>

            <el-form-item prop="position" label="职位：" :rules="readonly ? [] : newRule('职位', 'required', 'max20')">
                <el-input v-if="!readonly" v-model="form.position" clearable autocomplete="off" :placeholder="`请输入所任职职位`"></el-input>
                <div v-if="readonly" class="BgInput">{{form.position}}</div>
                <div v-if="readonly" class="BgLine"></div>
            </el-form-item>

            <!-- 手机号 -->
            <el-form-item prop="mobile" label="注册手机号：" :rules="newRule('手机号', 'required', 'mobile')">
                {{form.mobile}}
            </el-form-item>

            <template slot="btn" slot-scope="scope">
                <el-button type="primary" class="submit" @click="scope.submitHandler">
                    提交
                </el-button>
            </template>
        </my-form>

    </div>
</template>

<script>
export default {
    layout: 'client-user',
    props: {
        readonly: {
            type: Boolean,
            default: false,
            // default: true,
        },
    },
    data() {
        return {
            form: {
                dwname: '', //公司名字
                position: '', //职位
                prefer: '', //更喜欢
                systemwords: '', //	系统的 提供的关键词
                customwords: '', //	自定义关键词
                mobile: '',
                // checkList: [],
            },
            messageType: [],
            messageFenlei: [],
            customwords: '',

            messageTypeOptions: [],
            messageFenleiOptions: [],
            fenleiData: [],

            isCustom: true, //自选项
            isSystem: true, //系统选项
            isTime: false,
        };
    },
    computed: {},
    methods: {
        checkType(rule, value, callback) {
            if (this.form.prefer === 'bid') {
                if (this.messageFenlei.length !== 0 && this.messageType.length == 0) {
                    return callback(new Error('请勾选信息类型'));
                }
            }

            if (this.form.customwords == '') {
                if (this.messageType.length === 0) {
                    return callback(new Error('请勾选信息类型'));
                }
                callback();
            }
            callback();
        },
        checkFenlei(rule, value, callback) {
            if (this.form.prefer === 'bid') {
                if (this.messageFenlei.length === 0 && this.messageType.length !== 0) {
                    return callback(new Error('已勾选信息类型,未勾选信息分类'));
                }

                if (this.messageFenlei.length !== 0) {
                    callback();
                }
            }
            if (this.form.customwords.length != 0) {
                callback();
            }
            if (this.messageFenlei.length === 0) {
                return callback(new Error('请勾选信息分类'));
            }
        },

        inputCustom(rule, value, callback) {
            // messageType
            // messageFenlei
            // form.customwords

            if (this.form.prefer === 'bid') {
                if (this.form.customwords == '') {
                    callback();
                } else {
                    if (this.form.customwords.length < 2 || this.form.customwords.length > 100) {
                        return callback('请注意词的总长度。每个词最多输入2-20字，用空格隔开');
                    }
                    callback();
                }

                if (this.form.customwords === '') {
                    if (this.messageFenlei.length === 0) {
                        return callback(new Error('可提供的服务不能为空'));
                    }
                    callback();
                } else {
                    callback();
                }
            } else {
                if (this.form.customwords.length === 0) {
                    if (this.messageType.length === 0) {
                        return callback(new Error('可提供的服务不能为空'));
                    }
                    callback();
                } else {
                    callback();
                }
            }
            callback();
        },

        // 强制刷新视图
        changeInput(e) {
            let that = this;
            if (that.isTime) {
                that.isTime = false;
            } else {
                setTimeout(() => {
                    that.isTime = true;
                }, 500);
            }

            this.$forceUpdate();
        },

        //获取选项
        getSystemType(val = 'bid') {
            // 招标项目信息： bid  中标项目信息：zb
            let val2 = val == 'bid' ? 'zhao' : 'zhong';
           
            this.messageType = [];
            this.messageFenlei = [];
            let data = {
                type: val2,
            };
            this.$get(`${this.$store.state.api.tbUrl}/center/getsystemtype.json`, data, function (data) {
                // 数据由 data 改成 data.list .data下新加 customwords属性
                if (data.customwords) {
                    this.form.customwords = data.customwords.replace(/,/g, ' ');
                }

                if (val == 'bid') {
                    this.fenleiData = data.list;
                    this.messageTypeOptions = data.list;

                    //点击招标后，自动选择第一项
                    this.messageFenleiOptions = this.messageTypeOptions[0].children;
                    //又 选中的，就赋值选中的
                    this.messageTypeOptions.forEach((item) => {
                        if (item.select === '1') {
                            this.messageFenleiOptions = item.children;
                            return;
                        }
                    });

                    //过滤，第一层 信息类型
                    this.messageTypeOptions.forEach((item) => {
                        if (item.select === '1') {
                            this.messageType.push(item.valueid);
                        }
                    });
                    // 过滤，第二层 信息分类
                    this.messageFenleiOptions.forEach((item) => {
                        if (item.select === '1') {
                            this.messageFenlei.push(item.valueid);
                        }
                    });
                    //招标状态下  -勾选信息类型 -默认选中第一个
                    if (this.messageType.length === 0) {
                        this.messageType[0] = this.messageTypeOptions[0].valueid;
                    }
                } else {
                    this.messageTypeOptions = data.list;
                    // 过滤，
                    this.messageTypeOptions.forEach((item) => {
                        if (item.select === '1') {
                            this.messageType.push(item.valueid);
                        }
                    });
                }

                this.messageOnload();
            });
        },

        typeChange(val) {
            //等于 招标
            if (this.form.prefer != 'zb') {
                let nowData = clone(val);
                nowData[0] = nowData[nowData.length - 1];
                this.messageType = [];
                this.messageType[0] = nowData[0];
                if (val.length == 0) {
                    this.messageType = [];
                }
            }
            //当前选中的值
            let nowType = val[val.length - 1];
            for (let i = 0; i < this.messageTypeOptions.length; i++) {
                if (nowType == this.messageTypeOptions[i].valueid) {
                    this.messageFenleiOptions = this.messageTypeOptions[i].children;
                }
            }

            this.$forceUpdate();
        },

        fenleiChange(val) {},
        // 初始化更改，
        messageOnload() {},

        submitHandler() {
            this.disposeStr();
            let nowForm = clone(this.form);
            nowForm.customwords = nowForm.customwords.trim().split(/\s+/).join(',');

            let newForm = {
                dwname: nowForm.dwname, //公司名字
                position: nowForm.position, //职位
                prefer: nowForm.prefer, //更喜欢
                systemwords: nowForm.systemwords, //	系统的 提供的关键词
                customwords: nowForm.customwords, //	自定义关键词
                mobile: nowForm.mobile,
            };

            this.$store.dispatch('userinfo/adjustUserInfo', {
                data: newForm, //入参
                success: () => {
                    showMsg('修改成功！', 'success');
                    this.$emit('back');
                },
            });
        },

        // 处理字符串
        disposeStr() {
            // 判断招标，中标 字符串拼接; 系统定义的  和 自己定义的（相同处理放方式）
            this.form.systemwords = this.form.prefer != 'bid' ? this.messageType.join(',') : this.messageFenlei.join(',');
        },

        getUser() {
            this.$get(`${this.$store.state.api.webUrl}/userlogin/userinfo.json`, {}, function (data) {
                this.form = data;
                this.form.customwords = '';
                if (this.form.prefer == null) {
                    this.form.prefer = 'bid';
                }

                // this.getSystemType(data.prefer);
                // this.$forceUpdate();
                //初始化选项
                this.getSystemType(this.form.prefer);
            });
        },
    },
    mounted() {
        this.getUser();
    },
    components: {},
};
</script>

<style lang="scss" scoped>
@import '@css/var.scss';
.mybr {
    padding-top: 28px;
}

.info-form {
    .el-form {
        position: relative;
        width: 100%;
        min-height: 550px;
        margin-top: -30px;
        .el-input {
            width: 400px !important;

            input .el-input__inner {
                width: 380px !important;
                padding: 0 8px !important;
            }
        }

        .submit {
            width: 200px;
            font-size: 14px;
        }

        .el-form-item {
            margin-bottom: 18px !important;
        }
        .BgInput {
            background-color: #f0f0f0;
            margin-bottom: 4px;
            width: 400px;
            padding-left: 5px;
        }
        .BgLine {
            width: 400px;
            height: 1px;
            background: #6493f3;
        }

        //深度更改颜色。
        .re-color {
            ::v-deep {
                .el-radio__input.is-disabled + span.el-radio__label {
                    color: #333;
                }
                .el-checkbox__input.is-disabled + span.el-checkbox__label {
                    color: #333;
                }
            }
        }
    }
}
</style>