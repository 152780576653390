<template>
    <div
        class="input-mobile-yzm"
        :class="[size]"
    >
        <el-form
            ref="form"
            autocomplete="off"
            :label-width="labelWidth"
            :inline="true"
            :model="form"
            size="small"
            @submit="login"
        >

            <el-form-item
                prop="mobile"
                :label="size === 'small' ? '' : '手机号:'"
                :rules="newRule('手机号', 'required', 'mobile')"
            >
                <el-input
                    class="login-input"
                    v-model="form.mobile"
                    clearable
                    autocomplete="off"
                    :placeholder="size === 'small' ? '手机号' : `此手机号用于平台登录`"
                ></el-input>
            </el-form-item>

            <el-form-item
                prop="yzm"
                :rules="newRule('验证码', 'required')"
            >
                <div class="yzm-con">
                    <div class="left">
                        <el-input
                            class="input-yzm"
                            :placeholder="size === 'small' ? '验证码' : '手机验证码'"
                            v-model="form.yzm"
                        ></el-input>
                    </div>
                    <div class="right">
                        <el-button
                            plain
                            class="btn-yzm"
                            :disabled="btnDisabled"
                            @click="getYzm"
                            :loading="loadingController"
                        >
                            {{text}}
                        </el-button>
                    </div>
                </div>
            </el-form-item>
        </el-form>

        <div
            :class="['prompt' , promptPos]"
            v-if="!!prompt"
        >{{prompt}}</div>

    </div>
</template>

<script>
export default {
    props: {
        prompt: {
            type: String,
            default: 'prompt',
        },
        labelWidth: {
            type: String,
            default: '80px',
        },
        size: {
            type: String,
            default: 'medium',
        },
        promptPos:{
            type: String,
            default: 'prompt-left',
        },
    },
    components: {},

    data() {
        return {
            form: {
                mobile: '',
                yzm: '',
            },
            placeholder: '',
            model: '',
            loadingController: false,
            btnDisabled: false,
            text: '发送验证码',
        };
    },
    computed: {},
    methods: {
        login(cb) {
            let that = this;

            this.$refs['form'].validate(function (valid) {
                if (valid) {
                    that.$store.dispatch('userinfo/smsloginHandler', {
                        data: that.form,
                        success: (n) => {
                            that.$emit('login');
                            cb && cb();
                            that.$store.commit('userinfo/updateData', n);
                        },
                    });
                } else {
                }
            });
        },

        getYzm() {
            if (!this.form.mobile || !/^[1][0-9]{10}$/.test(this.form.mobile)) {
                ShowMsg('请确认手机号', 'warning');
                return;
            }

            this.$post(
                `${this.$store.state.api.webUrl}/userlogin/sendyzm.json`,
                {
                    mobile: this.form.mobile,
                },
                (data) => {
                    //倒计时
                    this.loadingController = true;
                    this.btnDisabled = true;
                    //倒计时
                    var remain = 60;
                    this.text = remain + 's后重试';
                    // this.text = '请在' + remain + 's后重试';

                    var int = setInterval(() => {
                        if (remain != 0) {
                            remain--;
                            this.text = remain + 's后重试';
                            // this.text = '请在' + remain + 's后重试';
                        } else {
                            clearInterval(int);
                            this.text = '发送验证码';
                            this.btnDisabled = false;
                            this.loadingController = false;
                        }
                    }, 1000);
                }
            );
        },
    },

    mounted() {},
};
</script>

<style scoped lang="scss">
@import '@css/var.scss';

.input-mobile-yzm {
    min-height: 60px;
    // overflow: hidden;
    margin: 0 auto;
    text-align: center;
    position: relative;

    &.medium {
        width: 600px;

        .yzm-con {
            .left {
                .input-yzm {
                    ::v-deep .el-input__inner {
                        width: 120px;
                    }
                }
            }

            .right {
                margin-left: 10px;
            }
        }
    }

    &.small {
        width: 336px;

        ::v-deep .el-form-item {
            margin-right: 4px;
        }

        .login-input {
            ::v-deep .el-input__inner {
                width: 130px;
            }
        }

        .yzm-con {
            .left {
                .input-yzm {
                    ::v-deep .el-input__inner {
                        width: 80px;
                    }
                }
            }

            .right {
                margin-left: 4px;
            }
        }

        .prompt{
            left: 0;
        }
    }

    .btn-yzm {
        width: 108px;
    }

    ::v-deep {
        .el-form--inline {
            display: flex;
        }

        .el-form-item {
            margin-bottom: 15px;
        }
    }

    .prompt {
        position: absolute;
        width: 100%;
        height: 20px;
        font-size: 14px;
        color: #999;
        bottom: 0px;
        margin: 0 auto;
    }
    .prompt-left{
        text-align: left;
        padding-left: 80px;
    }
    .prompt-center{
        text-align: center;
    }

    .yzm-con {
        display: flex;

        .left {
        }

        .right {
        }
    }
}
</style>