<template>
    <div class="search-baidu">
        <div class="left">
            <input 
                v-model="wd"
                type="text" 
                placeholder="百度一下"
                @keydown.enter="search"
            />

            <i 
                v-if="wd"
                class="el-icon-circle-close btn-clear"
                @click="clearHandler"
            ></i>
        </div>

        <div class="right">
            <div class="btn" @click="search">
                搜索
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            wd: '',
        };
    },
    methods: {
        search() {
            window.open(`https://www.baidu.com/s?wd=${this.wd}`);
        },
        clearHandler() {
            this.wd = '';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

    .search-baidu{
        display: flex;
        width: 275px;
        height: 32px;
	    border-radius: 2px;
        overflow: hidden;
        background: $primaryColor7;
        margin-right: 54px;

        .left{
            display: flex;
            flex: 1;
            padding: 0 10px;
            align-items: center;

            input{
                display: block;
                flex: 1;
                height: 100%;
                border: none;
                outline: none;
                background: transparent;
                color: white;

                &::-webkit-input-placeholder{
                    color: white;
                }

                &::-moz-placeholder{
                    color: white;
                }

                &::-ms-input-placeholder{
                    color: white;
                }
            }

            .btn-clear{
                cursor: pointer;
                color: white;
                margin-left: 4px;
            }
        }

        .right{
            position: relative;

            &:before{
                content: '';
                display: block;
                width: 1px;
                height: 12px;
                background: #d8d8d8;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            .btn{
                display: flex;
                align-items: center;
                color: white;
                font-size: 12px;
                padding: 0 1em;
                height: 100%;
                cursor: pointer;

                &:hover{
                    color: white;
                    background: $primaryColor8;
                }
            }
        }
    }
</style>