<template>
    <header class="header" :class="{fixed: fixed}">
        <div class="content">
            <img 
                class="logo header-com" 
                src="@images/header-logo.png"
            />

            <div class="nav-list header-com">
                <template v-for="(item, index) in $store.state.config.nav">
                    <nuxt-link
                    class="nav-item" 
                    :key="index"
                    :to="item.href"
                    :class="{active: isActive(item)}"
                    @click="clickHandler($event, item.click)"
                    >
                        {{item.title}}
                    </nuxt-link>
                </template>
            </div>

            <user></user>

            <ip></ip>

        </div>

        <slot></slot>
    </header>
</template>

<script>
import Ip from './components/ip';
import User from './components/user';

export default {
    components: {
        Ip,
        User,
    },
    props: {
        theme: {
            type: String,
            default: ''
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            
        }
    },
    computed: {
        
    },
    methods: {
        isActive(item) {
            var path = this.$route.path,
                href = item.href,
                activeReg = item.activeReg;

            if (href === path) {
                return true;
            }

            if (path === href) {
                return true;
            }

            if (activeReg && activeReg.test(path)) {
                return true;
            }

            return false;
        },
        clickHandler(e, cb) {
            if (cb) {
                e.preventDefault();
                
                var fun = new Function(cb);
                fun.call(this);
            }
        },
    },
    mounted: function() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import '@css/var.scss';

	.header{
		overflow: hidden;
		cursor: default;
		height: 70px;
        background: white;
        box-shadow: 0px 1px 6px 0px rgba(61, 126, 255, 0.15);

        &.fixed{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 10000;
            min-width: 1200px;
        }

		.header-com{ 
			float:left;
		}

		img.logo{
            width: 204px;
			height: 40px;
			margin: 12px 0;
		}

		.nav-list{
			font-size:16px;
			overflow: hidden;
            padding: 26px 0;
            margin-left: 92px;

			.nav-item{
				float:left;
				cursor: pointer;
				color: #333;
                position: relative;
                line-height: 1em;
                font-size: inherit;

				&:hover{
					color: $primaryColor;
				}

				& + .nav-item{
					margin-left:60px;
				}

				&.active{
					font-weight: bolder;
                    color: $primaryColor;
				}
			}
		}
	}

    .child-item{
        color: #333333;
    }
</style>
