import Vue from "vue";
import VueLazyload from 'vue-lazyload';

// v-xxx:arg="value"

Vue.directive("czc", {
    inserted: (el, {arg, value}) => {
        if (!Array.isArray(value)) {
            console.error('czc时间参数应为数组格式');
            return;
        }

        if (value.length !== 5) {
            console.error('czc参数长度错误');
            return;
        }
        if(value[0].length === 0 || !value[4]){
            console.error('事件类别、nodeid不能为空');
            return;
        }
        
        el.addEventListener('click', e => {
            if (window && window._czc) {
                var arr = ['_trackEvent', ...value];

                /*
                    ['_trackEvent', _trackEvent, action, label, value, nodeid]
                    category：事件类别，必填项，表示事件发生在谁身上，如“视频”、“小说”、“轮显层”等等。
                    action：事件操作，必填项，表示访客跟元素交互的行为动作，如”播放”、”收藏”、”翻层”等等。
                    label：事件标签，选填项，用于更详细的描述事件，从各个方面都可以，比如具体是哪个视频，哪部小说，翻到了第几层等等。
                    value：事件值，选填项，整数型，用于填写打分型事件的分值，加载时间型事件的时长，订单型事件的价格等等。
                    nodeid：div元素id，选填项，填写网页中的div元素id值，用于在“用户视点”功能上重绘元素的事件发生情况
                */

                _czc.push(arr);
            }
        });
    },
});

Vue.use(VueLazyload, {
    loading: '/images/icon/loading.gif',
});