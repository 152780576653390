<template>
    <my-dialog class="dialog-bind-mobile" v-model="model" title="请绑定手机号码" width="400px" @close="bindMobileClose">
        <img src="~@images/logo.png" alt="logo" class="logo">

        <my-form v-model="form" autocomplete="off" label-width="0" @submit="submitHandler">
            <el-form-item prop="mobile" :rules="newRule('手机号', 'required', 'mobile')">
                <el-input @input="yqmAdd" class="login-input" v-model="form.mobile" clearable autocomplete="off" :placeholder="`请输入手机号`"></el-input>
            </el-form-item>

            <el-form-item prop="yzm" :rules="newRule('验证码', 'required')">
                <input-yzm class="login-input" :mobile="form.mobile" v-model="form.yzm" :placeholder="`请输入验证码`"></input-yzm>
            </el-form-item>

            <el-form-item v-if="isYqmShow">
                <el-input class="login-input" v-model="yqm" :placeholder="`请输入邀请码`"></el-input>
            </el-form-item>

            <template slot="btn" slot-scope="scope">
                <el-button type="primary" class="btn-login" @click="scope.submitHandler">
                    绑定
                </el-button>

                <div class="info">
                    <span class="info-span-left">绑定号码代表您已同意{{CONFIG_PROJECT.title}}</span>
                    <span class="info-span" @click="dialogVisible = true">用户协议及隐私政策</span>
                </div>
            </template>
        </my-form>

        <dialog-clause v-model="dialogVisible" title="用户协议及隐私政策" type="login"></dialog-clause>

    </my-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import eventBus from './event-bus';
import CONFIG_PROJECT from '@config/project';
import InputYzm from '@components/sys/input-yzm';
import DialogClause from '@components/sys/dialog-clause';

export default {
    props: {
        // openid: {
        //     type: String,
        //     default: '',
        // },
    },
    components: {
        InputYzm,
        DialogClause,
    },
    data() {
        return {
            openid: '',
            unionid: '',
            
            isShowErr: true,
            form: {
                mobile: '',
                yzm: '',
            },
            yqm:'',
            isYqmShow: false,

            CONFIG_PROJECT,
            dialogVisible: false,
        };
    },
    computed: {
        ...mapGetters({
            dialogBindMobileVisible: 'userinfo/getDialogBindMobileVisible', //改 dialogBindMobileVisible
        }),
        model: {
            get() {
                return this.dialogBindMobileVisible;
            },
            set(val) {
                this.$store.commit('userinfo/updateDialogBindMobileVisible', val);
            },
        },
    },
    methods: {
        ...mapActions({
            closeLogin: 'global-dialog/closeLogin',
            loginCallbackDispatch: 'global-dialog/loginCallbackDispatch',
            openGlobalDialog: 'global-dialog/openGlobalDialog',
        }),
        ...mapMutations({
            updateData: 'userinfo/updateData',  //更新用户信息
        }),
        yqmAdd(val){
            //判断是否是手机号。
            if (val.length !== 11 || !/^[1][0-9]{10}$/.test(val)) {
                this.isYqmShow = false;
                console.log('手机号格式错误');
                return;
            }
            this.$get(`${this.$store.state.api.webUrl}/userlogin/isregist.json` , {
                mobile: this.form.mobile
            } , data => {
                this.isYqmShow = data.show;
            })
        },

        //绑定用户 手机号
        submitHandler() {
            let mydata = this.form;
            if(this.isYqmShow){
                mydata.registcode = this.yqm;
            };

            this.$post(`${this.$store.state.api.webUrl}/userlogin/wechatbind.json`, {
                ...mydata,
                openid: this.openid,
                unionid: this.unionid,
            }, data => {
                //存储用户信息 data ， 关闭弹窗，刷新页面，
                this.updateData(data);
                this.isShowErr = false;
                this.model = false;

                this.loginCallbackDispatch();     //弹窗登录成功以后逻辑

                // if (data.shbj == '1') {
                //     window.location.reload();
                // } else { //首次注册，登录，关闭登录弹窗，打开补充信息弹窗。
                //     this.closeLogin();

                //     this.openGlobalDialog({
                //         name: 'supplement',
                //     });
                // }

                window.location.reload();
            });
        },
        
        // 微信的信息清除掉，相当于没有登录成功
        bindMobileClose(bindType = 'err') {
            if (this.isShowErr == false) return;
            
            window.showConfirm('登录失败，用户取消绑定手机号');
        },

        getOpenid({openid, unionid}){
            this.openid = openid;
            this.unionid = unionid
        },
    },
    mounted() {
        eventBus.$on('setOpenid', this.getOpenid);
    },
};
</script>

<style scoped lang="scss">
@import '@css/var.scss';

.dialog-bind-mobile {
    .logo {
        display: block;
        width: 160px;
        margin: 0 auto 30px;
    }

    .info {
        padding: 0;
        .info-span {
            margin-left: -1px;
            color: $primaryColor;
            cursor: pointer;
        }
    }

    .code-name {
        line-height: 1em;
        margin-top: 15px;
        .icon {
            width: 20px;
            height: 20px;
            vertical-align: middle;
            color: #1aad19;
        }
    }
}

.login-input {
    border-bottom: 1px solid #e0e4e6;

    ::v-deep {
        .el-input__inner {
            background: transparent;
            border: none;
            border-radius: 0;
            padding: 0;
        }

        .el-input-group__append {
            border: none;
            background: transparent;

            &:hover {
                color: $primaryColor7;
                background-color: transparent;
            }

            .btn-yzm {
                padding: 9px 0;
            }
        }
    }
}

.btn-login {
    width: 100%;
    font-size: 16px;
    padding: 15px;
}
</style>