// 广告用store

import Vue from 'vue';

var loadingCities = false,
    loadingWeather = false;

export const state = () => ({
    cities:[],

    usersCityName: '',
    weatherInfo: null,
});

export const mutations = {
    // 更新cities列表
    updateCities(state, n) {
        state.cities = n;
    },
    // 更新用户所在城市名称
    updateUsersCityName(state, n) {
        state.usersCityName = n;
    },
    // 更新用户所在城市天气
    updateWeatherInfo(state, n) {
        if (!state.weatherInfo) {
            state.weatherInfo = {}
        }

        Vue.prototype.$set(state.weatherInfo, state.usersCityName, n);
    },
};

export const getters = {
    /**
     * 获取省级列表
     * @param {Object} state state
     * @returns 
     */
    getProvinces(state) {
        return state.cities;
    },
    
    /**
     * 用户所在省的市级列表
     * @param {Object} state state
     * @param {getters} state getters
     * @returns 
     */
    getCities(state, getters) {
        if (!state.cities || !state.cities.length) {
            return [];
        }

        if (!state.usersCityName) {
            return [];
        }

        return getters.usersProvince?.childList;
    },

    /**
     * 通过省code获取市列表
     * this.$store.getters['city/getCitiesByProvinceCode']('130000')
     * @param {Object} state state
     * @returns 
     */
    getCitiesByProvinceCode: (state) => (provinceCode) => {
        if (!provinceCode) {
            return [];
        }

        let targetProvince = state.cities.find(item => {
            return item.valueid === provinceCode
        });

        if (!targetProvince) {
            return [];
        }

        return targetProvince.childList;
    },

    // 用户所在省
    usersProvince(state) {
        if (!state.usersCityName) {
            return {
                valuename: '',
                valueid: '',
            };
        }

        var filteredProvince = state.cities.find(item => {
            return item.childList.some(child => {
                return child.valuename === state.usersCityName;
            });
        });

        if (!filteredProvince) {
            return {
                valuename: '',
                valueid: '',
            };
        }

        return filteredProvince;
    },
    // 用户所在市
    usersCity(state, getters) {
        var cityName = state.usersCityName,
            cities = getters.getCities;

        if (
            !cities
            || !cities.length
        ) {
            return {
                valuename: '',
                valueid: '',
            };
        }

        var filtered = cities.find(item => item.valuename == cityName);

        if (!filtered) {
            return {
                valuename: '',
                valueid: '',
            };
        }

        return filtered;
    },
    getWeatherInfo(state) {
        if (!state.weatherInfo) {
            return false;
        }

        return state.weatherInfo[state.usersCityName];
    },
};

export const actions = {
    queryCities({state, commit}, cb) {
        if (
            (
                state.cities 
                && state.cities.length
            )
            || loadingCities
        ) {
            cb && cb(state.cities);

            return;
        }

        loadingCities = true;

        Vue.prototype.$get(`${this.state.api.sysUrl}/common/citylist.json`, function (data) {
            commit('updateCities', data);

            cb && cb(data);

            loadingCities = false;
        });
    },

    /**
     * 获取天气及城市列表
     * @param {Object} param0 store信息
     * @param {Object} obj 参数
     * @returns 
     */
    queryWeather({state, commit, dispatch}, obj = {}) {
        dispatch('queryCities');

        if (
            loadingWeather
            || (state.weatherInfo && state.weatherInfo[state.usersCityName])
        ) {
            return;
        }

        var {cb, city} = obj;

        loadingWeather = true;

        Vue.prototype.$get(`${this.state.api.tbUrl}/home/getweather.json`, {
            city: state.usersCityName,
        }, data => {
            var {name} = data;

            // 请求失败时，重新请求
            // if (!data || !name) {
            //     loadingWeather = false;
            //     dispatch('queryWeather', obj);
            //     return;
            // }

            commit('updateUsersCityName', name);
            commit('updateWeatherInfo', data);

            cb && cb(data);

            loadingWeather = false;
        });
    },
};