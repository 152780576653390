import Vue from 'vue';
import storage from '@js/storage';

export const state = () => ({
    data: {
        totalamount: 0,
        sysamount: 0,
        questionamount: 0,
    },
    storeMsgCenter: {},
});

export const mutations = {
    updateData(state, n) {
        state.data = n;
    },
    updateMsgCenter(state, n) {
        if (typeof n === 'object') {
            state.storeMsgCenter = {
                ...state.storeMsgCenter,
                ...n,
            };

            storage.setSession({
                STORE_MSG_CENTER: state.storeMsgCenter,
            });
        }
    },
};

export const getters = {
    getData(state) {
        return state.data;
    },
    // 合计新消息数
    getTotal(state, getters) {
        return getters.getData.totalamount;
    },
    getMsgCenter(state) {
        var data = {};

        if(process.browser) {

            let storeMsgCenter = state.storeMsgCenter || {},
                keys = Object.keys(storeMsgCenter);

            if (keys.length) {
                data = state.storeMsgCenter;
            } else {
                data = storage.getSession('STORE_MSG_CENTER')
            }

        }
        
        return data;
    },
};

export const actions = {
    /**
     * 请求用户身份信息，仅在有SERIAL_CODE时请求
     * @param {Object} state state实例
     * @param {Object} obj 参数
     * @returns 
     */
     queryMsgNum(state) {
        const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');

        if (!SERIAL_CODE) return;

        // Vue.prototype.$get(`${this.state.api.webUrl}/message/getmessagenumber.json`, data => {
        //     state.commit('updateData', data);
        // });
    },
    // 触发消息并将消息移出事件中心
    releaseMsgCenter({getters, commit}, key) {
        var storeMsgCenter = getters.getMsgCenter,
            msgItem = storeMsgCenter[key];

        if (msgItem) {
            showMsgBox(msgItem);

            commit('updateMsgCenter', {
                [key]: '',
            });
        }
    },
    // 清空事件中心
    clearMsgCenter({getters, commit}) {
        var storeMsgCenter = getters.getMsgCenter;

        Object.keys(storeMsgCenter).forEach(key => {
            storeMsgCenter[key] = '';
        });

        commit('updateMsgCenter', storeMsgCenter);
    },
};