var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userinfo"},[(_vm.isLogin)?_c('div',{staticClass:"info-con"},[_c('span',{directives:[{name:"czc",rawName:"v-czc",value:([
                '导航栏',
                '点击按钮',
                '个人中心',
                0,
                "nav-btn-userCenter" ]),expression:"[\n                '导航栏',\n                '点击按钮',\n                '个人中心',\n                0,\n                `nav-btn-userCenter`,\n            ]"}],staticClass:"username",class:{ 'no-click': _vm.noClick },on:{"click":_vm.goUserCenter}},[_vm._v("\n            "+_vm._s(_vm.userNickname
                    ? _vm.userNickname.slice(0, 3) +
                      '****' +
                      _vm.userNickname.slice(7, 11)
                    : '')+"\n        ")]),_vm._v(" "),_c('span',{directives:[{name:"czc",rawName:"v-czc",value:(['导航栏', '点击按钮', '退出登录', 0, "nav-btn-logout"]),expression:"['导航栏', '点击按钮', '退出登录', 0, `nav-btn-logout`]"}],staticClass:"logout_btn",on:{"click":_vm.logoutClick}},[_vm._v("\n            退出\n        ")])]):_vm._e(),_vm._v(" "),(!_vm.isLogin)?_c('div',{staticClass:"info-con"},[_c('span',{directives:[{name:"czc",rawName:"v-czc",value:(['导航栏', '点击按钮', '登录/注册', 0, "nav-btn-login"]),expression:"['导航栏', '点击按钮', '登录/注册', 0, `nav-btn-login`]"}],staticClass:"login_btn",on:{"click":_vm.loginClick}},[_vm._v("\n            登录\n        ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }