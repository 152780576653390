<template>
    <div 
    v-if="showController"
    class="dialog-multi-platform"
    >
        <div class="img">
            <img src="/images/home/kehuduan.png" alt="下载"/>
        </div>

        <div class="btns">
            <a 
            v-if="btnClientShow"
            class="item btn-client"
            :href="getClientDownloadUrl()" 
            :download="CONFIG_PROJECT.downloadUrl.exe.filename"
            v-czc="['首页', '点击按钮' , '下载安装包',0 ,'main-btn-download-exe']"
            >
                客户端安装包
            </a>

            <!-- <a 
            class="item plain"
            :href="getClientDownloadUrl('zip')" 
            :download="CONFIG_PROJECT.downloadUrl.zip.filename"
            v-czc="['首页', '点击按钮' , '下载免安装',0 ,'main-btn-download-exe']"
            >
                下载免安装版
            </a> -->

            <div class="wxmp-con">
                <img src="/images/wx-mp-qrcode.png" alt="">

                <div class="text">
                    微信扫码打开小程序
                </div>
            </div>

            <div class="tel">
                400-901-6886 转 5
            </div>
        </div>

        <div class="action">
            <i class="el-icon-circle-close btn-close" @click="closeHandler"></i>
        </div>
    </div>
</template>

<script>
import CONFIG_PROJECT from '@config/project';

const CHECK_KEY = 'NO_SHOW_DIALOG_DOWNLOAD';

export default {
    data() {
        return {
            CONFIG_PROJECT,

            showController: false,

            btnClientShow: false,
        };
    },
    methods: {
        closeHandler() {
            this.showController = false;

            setSession({
                [CHECK_KEY]: true,
            });
        },
        testShow() {
            const BLACK_LIST = ['/application/biddingfiletool', '/application/sealed-bid-tool',];

            if (BLACK_LIST.includes(this.$route.path)) {
                // 黑名单中的界面不展示
                return;
            }

            var noShow = getSession(CHECK_KEY);

            this.showController = !noShow;
        },
    },
    mounted() {
        this.testShow();

        if (!window.electronApi) {
            // 非客户端展示客户端下载
            this.btnClientShow = true;
        }
    },
};
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .dialog-multi-platform{
        width: 120px;
        position: fixed;
        bottom: 24px;

        @media screen and (min-width: 1440px) {
            left: calc(50% + 612px);
        }

        @media screen and (max-width: 1440px) {
            right: 10px;
        }

        .img{
            width: 100%;

            img{
                display: block;
                width: 100%;
            }
        }

        .btns{
            margin-top: 8px;

            .item{
                display: block;
                font-size: 14px;
                text-align: center;
                background: $primaryColor;
                color: white;
                border-radius: 2px;
                padding: 7px 0;
                line-height: 1em;
                box-sizing: border-box;
                border: 1px solid $primaryColor;
                cursor: pointer;

                &:hover{
                    background: $primaryColor8;
                }

                &.plain{
                    background: transparent;
                    color: $primaryColor;

                    &:hover{
                        background: $primaryColor1;
                    }
                }

                & + .item{
                    margin-top: 8px;
                }
            }

            .btn-mp{
                margin-top: 8px;
            }
        }

        .action {
            margin-top: 8px;
            text-align: center;

            .btn-close{
                font-size: 16px;
                color: #C1C4C7;
                cursor: pointer;

                &:hover{
                    color: $primaryColor;
                }
            }
        }
    }

    .wxmp-con {
        text-align: center;
        background: white;
        border: 2px solid $primaryColor;
        border-radius: 2px;
        margin-top: 8px;
        padding: 8px 4px;

        img{
            display: block;
            width: 100%;
        }

        .text{
            margin-top: 4px;
            font-size: 12px;
            color: #595959;
            color: $primaryColor;
        }
    }

    .tel {
        font-size: 12px;
        text-align: center;
        margin-top: 8px;
        background: $warningColor;
        color: white;
        border-radius: 2px;
        padding: 7px 0;
    }
</style>