import Vue from 'vue';
import storage from '@js/storage';

export const state = () => ({
    navigateData:{},
    toolnavDate:{}, //第三方工具。

});

export const mutations = {
    updateNavigateData(state, n) {
        state.navigateData = n;
        
    },
    updateToolnavDate(state, n) {
        state.toolnavDate = n;
        
    },
};

export const getters = {
    navigateData(state) {
        return state.navigateData;
    },
    toolnavDate(state) {
        return state.toolnavDate;
    },
};

export const actions = {
    getNavigateData(state) {
        Vue.prototype.$ajax({
            type: "get",
            url: `${this.state.api.tbUrl}/home/getmiddlenavigate.json`,
            data: {
                proname:'河北省',
                proid:'130000'
            },
            callback: function (data, res) {
                state.commit('updateNavigateData', data);
            },
            error: function () {},
        });
    },
};