// 获取系统的用户来源，在用户首次注册的时候一并提交

if(process.browser) {
    var shareSourceB = getSearch('ssb'),     //来源平台
        shareSourceC = getSearch('ssc');     //来源人

    if (window.electronApi) {
        // 客户端
        shareSourceB = window.electronApi.ssb;
        shareSourceC = window.electronApi.ssc;
    }

    var storedSsb = getLocal('ssb'),
        storedSsc = getLocal('ssc');

    if (shareSourceB) {
        setLocal({
            ssb: shareSourceB,
        });
    }

    if (shareSourceC) {
        setLocal({
            ssc: shareSourceC,
        });
    }
}