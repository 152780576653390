<template>
    <transition name="el-fade-in-linear">
        <div 
        class="modal-poster"
        v-if="showController"
        >
            <div class="poster-item">
                <img 
                    class="poster" 
                    :src="bindProps.src" 
                    :alt="bindProps.name"
                />

                <div class="btn-close" @click="close">
                    <i class="el-icon-close"></i>
                </div>

                <a 
                class="btn-share"
                :style="btnShareStyle"
                :href="bindProps.src"
                target="_blank"
                :download="`${bindProps.name}.png`"
                >
                    <i class="iconfont">&#xe627;</i>
                    下载该名片分享给好友
                </a>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        // 海报地址
        src: {
            type: String,
            default: '/images/home/poster/make-tb-file.png',
        },
        // 海报标题
        name: {
            type: String,
            default: '标书制作',
        },
        // 分享按钮颜色组
        color: {
            type: Array,
            default: () => [
                '#000bab', // color
                '#82a1ff', // border-color
                '#e1e8ff', // background-color
            ],
        },
    },
    data() {
        return {
            showController: false,

            fromInner: false,

            innerProps: {
                src: '', // 海报地址
                name: '', // 海报标题
                color: [], // 分享按钮颜色组
            },
        };
    },
    computed: {
        bindProps() {
            if (this.fromInner) {
                return this.innerProps;
            }

            return {
                src: this.src,
                name: this.name,
                color: this.color,
            };
        },
        btnShareStyle() {
            return {
                'color': this.bindProps.color[0],
                'border-color': this.bindProps.color[1],
                'background-color': this.bindProps.color[2],
            }
        },
    },
    methods: {
        open(props = {src: '', name: '', color: [],}) {
            if (props) {
                this.fromInner = true,
                this.innerProps = props;
            } else {
                this.fromInner = false;
            }

            this.showController = true;
            this.$emit('open');
        },
        close() {
            this.showController = false;
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
    .modal-poster{
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .31);

        .poster-item{
            width: 520px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);            

            .poster{
                display: block;
                min-height: 300px;
            }

            .btn-close{
                position: absolute;
                top: 10px;
                right: 8px;
                font-size: 18px;
                line-height: 1em;
                color: white;
                cursor: pointer;
            }

            .btn-share{
                height: 39px;
                display: flex;
                align-items: center;
                position: absolute;
                right: 32px;
                bottom: 26px;
                font-size: 15px;
                line-height: 1em;
                font-weight: bold;
                padding: 0 18px;
                border-radius: 19px;
                border: 2px solid;
                cursor: pointer;

                .iconfont{
                    font-size: 15px;
                    margin-right: 5px;
                }
            }
        }
    }
</style>