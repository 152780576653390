<template>
<!-- 个人信息 nav -->
<el-card>
    <div class="center-nav">
        <div class="name">
            账号：
            <span>{{nickname}}</span>
        </div>

        <div class="btns">
            <div 
            v-for="item in configBtns" 
            :key="item.label" 
            @click="navClickHandler(item.url)"
            :class="{active:isActive(item)}"
            >
                <span>{{item.label}}</span>
            </div>

            <template v-if="$route.path != '/user/center'">
                <el-button 
                type="text" 
                v-for="item in configTexts" 
                :key="item.label" 
                @click="navClickHandler(item.url)"
                >
                    {{item.label}}
                </el-button>
            </template>
        </div>
    </div>
</el-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            config: [
                {
                    label: '个人信息修改',
                    type: 'button',
                    url:'/user/center/info'
                },
                {
                    label: '我的订单',
                    type: 'button',
                    url:'/user/center/order'
                },
                {
                    label: '返回个人中心主页',
                    type: 'text',
                    url:'/user/center'
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            userinfo: 'userinfo/getData',
            nickname: 'userinfo/getNickname',
        }),
        configBtns(){
            return this.config.filter(item => item.type == 'button')
        },
        configTexts(){
            return this.config.filter(item => item.type == 'text')
        }
    },
    methods: {
        isActive(row) {
            return row.url === this.$route.path;
        },
        navClickHandler(url) {
            this.goto(url);

            this.$store.dispatch('userinfo/queryUserInfo', {
                requery: true
            });
        },
    },
}
</script>


<style lang="scss" scoped>
    @import '@css/var.scss';    

    .center-nav{
        display: flex;
        align-items:center;

        .name {
            span {
                color: $primaryColor;
                font-weight: 600;
            }
        }

        .btns{
            width: 350px;
            margin-left: 60px;
            display: flex;
            align-items:center;
            color: $primaryColor;

            div {
                padding: 7px;
                border: 1px solid $primaryColor;
                border-radius: 3px;
                margin-right: 13px;
                cursor: pointer;

                &:hover{
                    color: #fff;
                    background-color: $primaryColor;
                }
                &.active {
                    color: #fff;
                    background-color: $primaryColor;
                }
            }

            span {
                cursor: pointer;
            }
        }
    }
</style>