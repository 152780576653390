export const state = () => ({
    // defaultUserPage: '/user/application/business-inquiries',
    defaultUserPage: '/user/center',

    config: {
        nav: [],
        // security: {
        //     text: '京公网安备 11010802039310号',
        //     code: '11010802039310',
        // },

        tel: '400-901-6886 转 5',
        // email: '',
        workTime: '9:00-18:00',
        record: 'ICP备案号：京ICP备2020039654号-7',
        address: '北京市海淀区城西路辅路莲花苑5号 华宝大厦',
        reportEmail:'hb@zchrkj.com',
        reportTel: '400-901-6886',
        companyAbout:[
            {
                title: '关于我们',
                href: 'http://www.zchrkj.com/pages/about.html'
            },
            {
                title: '法律声明',
                href: 'http://www.zchzb.com/resource-web/pages/companyinfo/index.html?active=1'
            },
            {
                title: '工具下载',
                href: 'http://www.zchzb.com/resource-web/pages/companyinfo/index.html?active=2'
            },
        ],
        products: [
            {
                title: '招标采购交易平台',
                href: 'http://www.zchrkj.com/pages/zhaobiaocaigou.html'
            },
            {
                title: '保函办理平台',
                href: 'http://www.zchrkj.com/pages/baohanbanli.html'
            },
            {
                title: '专家管理平台',
                href: 'http://www.zchrkj.com/pages/zhuanjiaguanli.html'
            },
            {
                title: '线上培训平台',
                href: 'http://www.zchrkj.com/pages/xianshangpeixun.html'
            },
            {
                title: '云咨询服务平台',
                href: 'http://www.zchrkj.com/pages/yunzixun.html'
            },
        ],

        transferAccountsInfo: {
            bank: '中国建设银行北京六里桥支行',
            account: '11050137540000000372',
            name: '中财惠瑞（北京）科技发展有限公司',
        },
    }
})

export const mutations = {
    
}


export const getters = {
    
}
