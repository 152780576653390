<template>
    <my-dialog 
    class="dialog-supplement" 
    v-model="model" 
    title="补充信息" 
    width="700px"
    @close="back"
    >
        <el-link slot="head" type="info" :underline="false"> 
            &nbsp;&nbsp;&nbsp;&nbsp; 潜在项目推送、共享文件等功能需要您完善信息可方可使用
        </el-link>

        <info-form @back="back"></info-form>

    </my-dialog>
</template>

<script>
import InfoForm from '@/pages/user/center/info/components/form.vue';
import CONFIG_PROJECT from '@config/project';
import { mapGetters } from 'vuex';

export default {
    props: {},
    components: { InfoForm },
    data() {
        return {
            dialogWidth: '50%',
            CONFIG_PROJECT,
            dialogVisible: false,
        };
    },
    computed: {
        model: {
            get() {
                return this.$store.getters['global-dialog/getGlobalDialogVisible']('supplement');
            },
            set(val) {
                this.$store.commit('global-dialog/updateDialogVisible', {
                    name: 'supplement',
                    visible: val,
                });
            },
        },
    },
    methods: {
        //提交 关闭弹窗
        back() {
            this.model = false;
            // window.location.reload();

            this.$store.dispatch('userinfo/queryUserInfo', {
                requery: true,
            });
        },

    },
    mounted() {},
};
</script>

<style scoped lang="scss">
@import '@css/var.scss';

.dialog-supplement {
    ::v-deep .el-dialog__body {
        padding: 0 30px 30px 0 !important;
    }

    .logo {
        display: block;
        width: 160px;
        margin: 0 auto 30px;
    }

    .info {
        span {
            color: $primaryColor;
            cursor: pointer;
        }
    }
}
</style>