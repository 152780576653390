<template>
    <div class="daily-events">
        <dialog-sign ref="dialogSign"></dialog-sign>

        <dialog-custom 
            v-model="dialogCustomVisible" 
            @click="showLogin" 
        ></dialog-custom>
    </div>
</template>

<script>
import storage from '@js/storage';
import app from '@js/app-node';
import dialogWaitList from '@js/dialog-wait-list.js';

import DialogSign from './components/dialog-sign.vue';
import DialogCustom from '../dialog-custom';

const NAME_SPACE = 'daily_event',
    WHITE_LIST = 'noLoginSign';
// 检查日期
function checkTodayActived() {
    var local = storage.getLocal(NAME_SPACE) || {};
        if(!local[WHITE_LIST]){
            return 1;
        }
        let todays = local[WHITE_LIST] || '';
        
        //是否为当天，不相同则进行1,
        if(todays === date){
            return 0;
        } else {
            return 1;
        }
}

//更新日期
function updateTodayActive() {
    var local = storage.getLocal(NAME_SPACE) || {};

    local[WHITE_LIST] = date;

    storage.setLocal({
        [NAME_SPACE]: local,
    });
}

//获取当前日期
var date = (function() {
    var d = new Date();
    return `${d.getFullYear()}-${d.getMonth() +1}-${d.getDate()}`;
}());

export default {
    components: {
        DialogSign,
        DialogCustom,
    },
    data() {
        return {            
            dialogCustomVisible: false,
        };
    },
    methods: {
        /**
         * 校验用户标签、签到、问答等信息
         */
        checkUserStatus() {
            const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');

            if(!checkTodayActived() && !SERIAL_CODE){
                return;
            }

            this.$get(`${this.$store.state.api.sysUrl}/signin/checkuserstatus.json`, (data) => {
                //更新
                if(!data.loginStatus){
                    // 未登录

                    // 在列队中注册-登录引导
                    dialogWaitList.push(() => {
                        this.dialogCustomVisible = true;
                    }, 1);

                    updateTodayActive();

                    return;
                }
                
                this.showSignDialog(data);
            });
        },
        /**
         * 展示签到界面
         */
        showSignDialog(data) {
            if (data.todaySignIn) {
                return;
            }

            // data.loginStatus = false,

           // 在列队中注册-签到弹框
            dialogWaitList.push(() => {
                this.$refs.dialogSign.open({
                    data,
                    onclose: () => {
                        dialogWaitList.next();
                    },
                });
            }, 1);
        },
        
        showLogin() {
            this.$store.dispatch('global-dialog/showLogin');
        },
    },
    mounted() {
        this.checkUserStatus();
    },
};
</script>