/**
 * order 权重
 * 邀请注册 0
 * 签到 1
 * 引导 2
 * 使用次数弹窗 3
 */

// 等待时间
const WAITING_TIME = 500;

var _list = [], // 序列
    _timer = null, // 定时器
    _activeIndex = 0; // 序列当前索引

/**
 * 定时启动
 * @returns _list 序列
 */
function _autoStart() {
    _stopTimer();

    _timer = setTimeout(() => {
        next();
    }, WAITING_TIME);

    return _list;
}

/**
 * 关闭定时器
 */
function _stopTimer () {
    try {
        clearTimeout(_timer);

        _timer = null;
    } catch(e) {}
}

/**
 * 给序列内容排序
 * @returns _list 排序后的序列
 */
function _sortList () {
    _list.sort((a, b) => {
        return a.order - b.order;
    });

    return _list;
}

/**
 * 入列、排序、等待时间结束后立即执行
 * @param {Function} fun 要执行的函数，应对应弹框弹出方法本身，不应有判断等逻辑
 * @param {Number} order 排序权重
 */
function push(fun, order = 0) {
    _activeIndex = 0;

    // 放入列队
    _list.push({
        handler: fun,
        order,
    });

    // 更新顺序
    _sortList();

    // 倒计时自动运行
    _autoStart();
};


/**
 * 从0开始按order依次执行
 */
function next() {
    var current = _list[_activeIndex];

    if (!current) {
        return;
    }

    var {handler} = current;

    handler();

    _activeIndex ++;

    if (_list.length <= _activeIndex) {
        clear();
    }
}

/**
 * 清空序列及相关内容
 */
function clear() {
    _activeIndex = 0;
    _list = [];
    _stopTimer();
}

export default {
    push,
    next,
    clear,
}