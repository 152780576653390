<template>
    <div class="layout-default">
        <div class="page-body">
            <slot>
                <nuxt />
            </slot>
        </div>

        <el-backtop 
            class="backtop-layout"
            target="#__nuxt"
        ></el-backtop>

        <!-- <my-footer></my-footer> -->

        <dialog-login></dialog-login>

        <dialog-supplement></dialog-supplement>
        <dialog-share></dialog-share>

        <cnzz></cnzz>
    </div>
</template>

<script>
import DialogLogin from '../pages/login/components/dialog-login';
import DialogSupplement from '../pages/login/components/dialog-supplement.vue';
import DialogShare from '@components/sys/dialog-share.vue';

import { mapActions } from 'vuex';

export default {
    name: 'layout-default',
    components: {
        DialogLogin,
        DialogSupplement,
        DialogShare,
    },
    data() {
        return {
            
        };
    },
    methods: {
        ...mapActions({
            showLogin: 'global-dialog/showLogin',
        }),
        dealCenterClick(){
            window.open(`/deal-nav`)
        }
    },
    mounted: function() {
        this.$store.dispatch('server/checkTime');
        if (window.electronApi) {
            var that = this;

            window.electronApi.addEventListener('login', () => {
                // 显示登陆弹窗逻辑代码
                that.showLogin()
            });

            window.electronApi.addEventListener('logout', () => {
                // 退出登录逻辑代码
                that.$store.dispatch('userinfo/logoutHandler');
            });
        }
    }
};
</script>

<style scoped lang="scss">
.layout-default {
    background-color: #f5f7fd;
    width: 100%;
    min-height: 100%;
    // overflow:auto;
}

.page-body {
    // margin: 10px auto;
    // height: 100%;
    // overflow:auto;
}

// .backtop-layout{
    
// }
</style>