<template>
    <div class="userinfo">
        <div v-if="isLogin" class="info-con">
            <span
                class="username"
                :class="{ 'no-click': noClick }"
                @click="goUserCenter"
                v-czc="[
                    '导航栏',
                    '点击按钮',
                    '个人中心',
                    0,
                    `nav-btn-userCenter`,
                ]"
            >
                {{
                    userNickname
                        ? userNickname.slice(0, 3) +
                          '****' +
                          userNickname.slice(7, 11)
                        : ''
                }}
            </span>

            <span
                class="logout_btn"
                @click="logoutClick"
                v-czc="['导航栏', '点击按钮', '退出登录', 0, `nav-btn-logout`]"
            >
                退出
            </span>
        </div>

        <div v-if="!isLogin" class="info-con">
            <span
                class="login_btn"
                @click="loginClick"
                v-czc="['导航栏', '点击按钮', '登录/注册', 0, `nav-btn-login`]"
            >
                登录
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        afterLogout: {
            type: [Function, Boolean],
            default: null,
        },
        // 用户名点击后无操作
        noClick: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            userNickname: 'userinfo/getNickname',
        }),

        isLogin() {
            if (this.$cookies.get('SERIAL_CODE')) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        ...mapActions({
            userlLogout: 'userinfo/logoutHandler',
            showLogin: 'global-dialog/showLogin',
            queryUserInfo: 'userinfo/queryUserInfo',
        }),
        goUserCenter() {
            if (this.noClick) {
                return;
            }

            this.$router.push('/user/center');
        },
        logoutClick: function () {
            ShowConfirm('是否退出当前用户', 'warning', () => {
                this.userlLogout(this.afterLogout);
            });
        },
        loginClick: function () {
            this.showLogin();
        },
    },
    mounted() {
        this.queryUserInfo();
    },
};
</script>

<style lang="scss" scoped>
.userinfo {
    display: flex;
    flex: 1;
    color: white;
    font-size: 15px;

    .info-con {
        display: flex;
    }

    .username {
        margin-right: 8px;
        cursor: pointer;

        &.no-click {
            cursor: default;
        }
    }

    .username::before {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }

    .username:hover {
        text-shadow: 0px 1px 1px white;
        // text-decoration: underline;
    }

    .logout_btn {
        cursor: pointer;
    }

    .logout_btn:hover {
        font-weight: bold;
    }

    .login_btn {
        cursor: pointer;
        border: 1px solid #6689ff;
        border-radius: 20px;
        padding: 2px 8px;
        background-color: #6689ff;
        transition: all 0.3s;
    }

    .login_btn:hover {
        border-color: white;
    }
}
</style>