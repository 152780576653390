<template>
    <el-collapse-transition>
        <div class="header-sys" v-if="showNav">
            <div class="left">
                <logo-block class="logo-space"></logo-block>
            </div>

            <div class="center client-drag">
                
            </div>

            <div class="right">
                <!-- <search-baidu></search-baidu> -->

                <menu-nav></menu-nav>

                <user-info></user-info>

                <btn-share></btn-share>

                <client-btns 
                    v-if="isClient"
                    class="client-btn-group"
                ></client-btns>
            </div>
        </div>
    </el-collapse-transition>
</template>


<script>
import LogoBlock from './components/logo.vue';
import UserInfo from './components/user-info.vue';
import ClientBtns from './components/client-btns.vue';
import SearchBaidu from './components/search-baidu.vue';
import MenuNav from './components/menu-nav.vue';
import BtnShare from './components/btn-share.vue';

export default {
    props: {},
    components: {
        LogoBlock,
        UserInfo,
        ClientBtns,
        SearchBaidu,
        MenuNav,
        BtnShare,
    },
    data() {
        return {            
            showNav: true, // 展示上导航
            isClient: false, // 是客户端环境
        };
    },
    created() {
        var settingNoNav = this.getQuery('setting_no_nav');

        if (
            settingNoNav
        ) {
            this.showNav = false;
        } else {
            this.showNav = true;
        }
    },
    mounted() {
        if (window.electronApi) {
            this.isClient = true;
        }
    },
};
</script>

<style lang="scss" scoped>
@import '@css/var.scss';
.header-sys {
    display: flex;
    justify-content: center;
    height: $topNavBarHeight;
    padding: 0 10px;
    background-color: $primaryColor;
    z-index: 2;

    .left, .center, .right{
        display: flex;
        align-items: center;
    }

    .left {
        .logo-space{
            // margin-right: 60px;
        }
    }

    .center{
        flex: 1;

        &.client-drag{
            -webkit-app-region: drag;
        }
    }

    .right{
        .client-btn-group{
            margin-left: 8px;
            margin-right: -10px;
        }
    }
}
</style>