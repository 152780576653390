<template>
    <el-button
        class="btn-yzm"
        slot="append"
        :type="type"
        :disabled="btnDisabled"
        @click="getYzm"
        :loading="loadingController"
    >
        {{ text }}
    </el-button>
</template>

<script>
import MODEL from '@mixins/model';

export default {
    mixins: [MODEL],
    props: {
        mobile: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'primary',
        },
    },
    data() {
        return {
            loadingController: false,
            btnDisabled: false,
            text: '获取手机验证码',
        };
    },
    methods: {
        getYzm() {
            if (!this.mobile) {
                ShowMsg('手机号不能为空，请填写手机号', 'warning');
                return;
            }

            if (!/^[1][0-9]{10}$/.test(this.mobile)) {
                ShowMsg('手机号格式错误，请填写正确的手机号', 'warning');
                return;
            }

            this.$post(
                `${this.$store.state.api.webUrl}/userlogin/sendyzm.json`,
                {
                    mobile: this.mobile,
                },
                (data) => {
                    //倒计时
                    this.loadingController = true;
                    this.btnDisabled = true;
                    //倒计时
                    var remain = 60;
                    this.text = '请在' + remain + 's后重试';

                    var int = setInterval(() => {
                        if (remain != 0) {
                            remain--;
                            this.text = '请在' + remain + 's后重试';
                        } else {
                            clearInterval(int);
                            this.text = '获取手机验证码';
                            this.btnDisabled = false;
                            this.loadingController = false;
                        }
                    }, 1000);
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
</style>