<template>
    <!-- <my-dialog class="dialog-login" v-model="model" title="注册/登录" width="400px"> -->
    <dialog-custom 
    v-model="model" 
    :theme="loginDialogProp.theme" 
    :context="loginDialogProp.context"
    >
        <div class="dialog-login">
            <my-form v-model="form" autocomplete="off" label-width="0" @submit="submitHandler">

                <div class="form-inner">
                    <el-form-item prop="mobile" :rules="newRule('手机号', 'required', 'mobile')">
                        <el-input @input="yqmAdd" class="login-input" v-model="form.mobile" clearable autocomplete="off"
                            :placeholder="`请输入手机号`"></el-input>
                    </el-form-item>

                    <el-form-item prop="yzm" :rules="newRule('验证码', 'required')">
                        <input-yzm class="login-input" :mobile="form.mobile" v-model="form.yzm"
                            :placeholder="`请输入验证码`"></input-yzm>
                    </el-form-item>
                </div>

                <!-- 后六位，非必填 -->
                <el-form-item v-if="isYqmShow">
                    <el-input class="login-input" v-model="yqm" :placeholder="`如有邀请码请输入，可领取专属福利`"></el-input>
                </el-form-item>

                <template slot="btn" slot-scope="scope">
                    <el-button type="primary" class="btn-login" @click="scope.submitHandler">
                        登录
                    </el-button>

                    <div class="info">
                        登录代表您已同意{{CONFIG_PROJECT.title}}
                        <span @click="dialogVisible = true">用户协议及隐私政策</span>
                    </div>
                </template>
            </my-form>

            <div v-if="!isClient" class="code-name" @click.stop.prevent="goWxLogin">
                <img class="icon" src="/images/login/微信登录.png" alt="微信登录">
                <a href="">微信登录</a>
            </div>
        </div>
        
        <dialog-clause v-model="dialogVisible" title="用户协议及隐私政策" type="login"></dialog-clause>
    </dialog-custom>
    <!-- </my-dialog> -->
</template>

<script>
import CONFIG_PROJECT from '@config/project';

import eventBus from './event-bus';

import dialogCustom from '@components/sys/dialog-custom';
import InputYzm from '@components/sys/input-yzm';
import DialogClause from '@components/sys/dialog-clause';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import WxQrLogin from '../js/WxQrLogin.js';

export default {
    props: {},
    components: {
        dialogCustom,
        InputYzm,
        DialogClause,
    },
    data() {
        return {
            CONFIG_PROJECT,

            form: {
                mobile: '',
                yzm: '',
            },
            yqm:'',
            isYqmShow: false,

            dialogVisible: false,

            wxQrLogin: null,

            isClient: false,
        };
    },
    computed: {
        model: {
            get() {
                return this.$store.getters['global-dialog/getGlobalDialogVisible']('login');
            },
            set(val) {
                this.$store.commit('global-dialog/updateDialogVisible', {
                    name: 'login',
                    visible: val,
                });
            },
        },
        loginDialogProp() {
            return this.$store.state['global-dialog'].login.props;
        },
    },
    methods: {
        ...mapActions({
            getNavigateData: 'navigate/getNavigateData',
            showBindMobile: 'userinfo/showBindMobile',
            loginCallbackDispatch: 'global-dialog/loginCallbackDispatch',
        }),
        ...mapMutations({
            updateNavigateData: 'navigate/updateNavigateData',
            updateData: 'userinfo/updateData', //更新用户信息
        }),

        //
        yqmAdd(val) {
            return;
            
            //判断是否是手机号。
            if (val.length !== 11 || !/^[1][0-9]{10}$/.test(val)) {
                this.isYqmShow = false;
                
                return;
            }
            this.$get(
                `${this.$store.state.api.webUrl}/userlogin/isregist.json`,
                {
                    mobile: this.form.mobile,
                },
                (data) => {
                    this.isYqmShow = data.show;
                }
            );
        },

        submitHandler() {
            let data = this.form;

            if (this.isYqmShow) {
                data.registcode = this.yqm;
            }

            this.$store.dispatch('userinfo/smsloginHandler', {
                data: data,
                success: (n) => {
                    this.$emit('login');
                    this.model = false;

                    this.loginCallbackDispatch();

                    if (n.shbj == '1' && this.$route.path !== '/') {
                        window.location.reload();
                    }
                },
            });
        },

        goWxLogin() {
            if (!this.wxQrLogin) {
                this.wxQrLogin = new WxQrLogin({
                    onscan: ({ state, code }) => {
                        this.WxLoginChoice(state, code);
                    },
                    onscancomplete: () => {
                        this.model = false;
                    },
                });
            }

            this.wxQrLogin.openQrcode.call(this);
        },
        //微信登录回调
        WxLoginChoice(state, code) {
            let that = this;
            this.$store.dispatch('userinfo/wxLoginHandler', {
                wxdata: {
                    code: code,
                    state: state,
                },
                success: (userdata) => {
                    this.loginCallbackDispatch(); //弹窗登录成功以后逻辑

                    if (userdata.shbj == '1' && this.$route.path !== '/') {
                        window.location.reload();
                    }
                },
                notbind: (bindinfo) => {
                    //兄弟传参,给 dialog-bind-mobile
                    eventBus.$emit('setOpenid', bindinfo);
                    that.showBindMobile(); //打开绑定弹窗
                },
            });
        },
    },

    mounted() {
        if (window.electronApi) {
            this.isClient = true;
        }
    },
    beforeDestroy() {
        try {
            this.wxQrLogin.destroy();
        } catch(e) {
            // e
        }
    },
};
</script>

<style scoped lang="scss">
@import '@css/var.scss';

$inputWidth: 288px;
$inputHeight: 50px;

.dialog-login {
    width: 620px;
    margin: 15px auto;
    text-align: center;
    letter-spacing: 1px;

    .info {
        margin: 10px 0;
        span {
            color: $primaryColor;
            cursor: pointer;
        }
    }

    .code-name {
        line-height: 1em;
        margin-top: -20px;
        .icon {
            width: 30px;
            height: 30px;
            vertical-align: middle;
            color: #1aad19;
        }
        a {
            color: #606266;
        }
        a:hover {
            color: #3b68ff;
            text-decoration: underline;
        }
    }

    .form-inner {
        display: flex;
        justify-content: space-between;
    }

    ::v-deep .btn-row {
        margin-top: 5px;
    }

    .btn-login {
        width: $inputWidth;
        font-size: 16px;
        padding: 16px;
        border-radius: 0;
    }
    ::v-deep .el-form-item__error {
        font-size: 14px;
        padding-top: 2px;
    }
}

.login-input {
    // border-bottom: 1px solid #e0e4e6;
    background-color: #f3f4fb;
    width: $inputWidth;
    height: $inputHeight;
    font-size: 16px;

    ::v-deep {
        .el-form-item__content {
            width: $inputWidth;
        }
        .el-input__inner {
            background: transparent;
            border: none;
            border-radius: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            padding: 0 12px;
        }

        .el-input-group__append {
            border: none;
            // background: transparent;
            background: none !important;

            &:hover {
                color: $primaryColor7;
                // background-color: transparent;
                background: none !important;
            }

            .btn-yzm {
                padding: 9px 12px;
            }

            .el-button.is-loading:before{
                background-color: rgba($color: #000000, $alpha: 0) ;
            }
        }
    }
}
.el-form-item__error {
    font-size: 15px;
    padding-top: 2px;
}
</style>