/**
 * 控制全局弹窗
 */

export const state = () => ({
    _storeDefaultProps: {}, // 缓存props默认值

    login: {
        visible: false,
        props: {
            theme: undefined,  // 弹框主题
            context: undefined, // 上方banner提示内容
            loginCallback: null, // 使用弹框成功登录后的回调
        },
    },

    supplement: {
        // 补充个人信息
        visible: false,
        props: {
            callback: null, // 补充完毕回调
        },
    },

    // 分享弹窗
    share: {
        visible: false,
        props: {}
    },

    // 购买服务
    product: {
        visible: false,
        props: {
            projectids: null, // 默认选中的项
            onclose: null, // 关闭回调
        }
    },
});

/**
 * 更新props，调用时会和默认props值运算
 * @param {Object} state state
 * @param {String} name 命名空间
 * @param {Object} props 参数对象
 */
function _updateStateProps(state, name, props) {
    var oldProps = state[name].props,
        storedDefaultProps = state._storeDefaultProps[name];

    if (!storedDefaultProps) {
        // 进行缓存
        state._storeDefaultProps[name] = oldProps;
    } else {
        oldProps = storedDefaultProps;
    }

    return {
        ...oldProps,
        ...props,
    };
}

export const mutations = {
    // 更新显示状态及参数
    updateDialogVisible(state, {name, visible, ...props}) {
        state[name].visible = visible;

        if (props && Object.keys(props).length) {
            state[name].props = _updateStateProps(state, name, props);
        }
    },
    // 仅更新参数
    updateProps(state, {name, ...props}) {
        state[name].props = _updateStateProps(state, name, props);
    },
    clearCallbackByName(state, {name, fname}) {
        state[name]['props'][fname] = null;
    },
};

export const getters = {
    getGlobalDialogVisible: (state) => (name) => {
        return state[name].visible;
    },
    getGlobalDialogProps: (state) => (name) => {
        return state[name].props;
    },
};

export const actions = {
    /**
     * 开启弹窗
     * @param {Object} param0 stroe
     * @param {Object} param1 配置
     */
    openGlobalDialog({commit}, {name, ...props}) {
        commit('updateDialogVisible', {
            name,
            visible: true,
            ...props,
        });
    },
    /**
     * 关闭弹窗
     * @param {Object} param0 stroe
     * @param {Object} param1 配置
     */
    closeGlobalDialog({commit}, {name}){
        commit('updateDialogLoginVisible', {
            name,
            visible: false,
        });
    },

    callbackGlobalDialog({state, commit}, name) {
        var arr = name.split('/'),
            nameSpace = arr[0],
            funName = arr[1];

        var callback = state[nameSpace]['props'][funName];

        if (callback && typeof callback === 'function') {
            callback();
        }

        commit('clearCallbackByName', {
            name: nameSpace,
            fname: funName,
        });
    },

    // login弹框特化
    /**
     * 打开登录弹框
     * @param {Object} param0 store
     * @param {Function | Object} param1 登录成功回调|props配置
     */
    showLogin({commit}, param1) {
        var visibleObj = {
            name: 'login',
            visible: true,
            loginCallback: null,
        };

        if (param1 && typeof param1 === 'function') {
            // param1为登录成功的回调
            visibleObj.loginCallback = param1;
        }

        if (param1 && typeof param1 === 'object') {
            // param1为弹框配置
            visibleObj = {
                ...visibleObj,
                ...param1,
            }
        }

        commit('updateDialogVisible', visibleObj);
    },
    /**
     * 关闭登录弹框
     * @param {Object} param0 store
     */
    closeLogin({commit}){
        commit('updateDialogVisible', {
            name: 'login',
            visible: false,
        });
    },
    /**
     * 触发登录回调
     * @param {Object} param0 store
     */
    loginCallbackDispatch({state, commit}) {
        var callback = state.login.props?.loginCallback;

        if (callback && typeof callback === 'function') {
            callback();
        }

        commit('clearCallbackByName', {
            name: 'login',
            fname: 'loginCallback',
        });
    },
};