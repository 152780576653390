import Vue from 'vue'
import CONFIG_PROJECT from '@config/project';
import storage from '@js/storage';

(function (owner) {
    /**
     * 获取路由中参数
     * @param  {String} key 参数关键字
     * @return {String}     获取到的参数
     */
    owner.getRoute = function (key) {
        return this.$route.params[key];
    };
    owner.getQuery = function (key) {
        if (key) {
            return this.$route.query[key];
        } else {
            return this.$route.query;
        };
    };

    //埋点函数
    owner.setCzc = function(value){
        if (!Array.isArray(value)) {
            console.error('czc时间参数应为数组格式');
            return;
        }

        if (value.length !== 5) {
            console.error('czc参数长度错误');
            return;
        }
        if(value[0].length === 0 || !value[4]){
            console.error('事件类别、nodeid不能为空');
            return;
        }

        if (window && window._czc) {
            var arr = ['_trackEvent', ...value];
            /*
                ['_trackEvent', category, action, label, value, nodeid]
                category：事件类别，必填项，表示事件发生在谁身上，如“视频”、“小说”、“轮显层”等等。
                action：事件操作，必填项，表示访客跟元素交互的行为动作，如”播放”、”收藏”、”翻层”等等。
                label：事件标签，选填项，用于更详细的描述事件，从各个方面都可以，比如具体是哪个视频，哪部小说，翻到了第几层等等。
                value：事件值，选填项，整数型，用于填写打分型事件的分值，加载时间型事件的时长，订单型事件的价格等等。
                nodeid：div元素id，选填项，填写网页中的div元素id值，用于在“用户视点”功能上重绘元素的事件发生情况
            */
            _czc.push(arr);
        }
    };

    /**
     * 获取$store中的变量
     * @param  {key} key 变量关键字
     * @return {all}     获取到的变量
     */
    owner.getStore = function (key) {
        return this.$store.state[key] || false;
    };

    /**
     * 设定$store中的变量
     * @param  {Object} obj 变量的key、value集合
     * @return {null}     返回空
     */
    owner.setStore = function (key, value) {
        var obj = {};
        if (typeof (key) === 'string') {
            obj[key] = value;
        } else if (typeof (key) === 'object') {
            obj = key;
        }
        this.$store.commit('setState', obj)
    };

    /**
     * 获取$store.getters中的变量
     * @param  {key} key 变量关键字
     * @return {all}     获取到的变量
     */
    owner.getGetters = function (key) {
        return this.$store.getters[key] || false;
    };

    /**
     * 跳转（有历史记录）
     * @return {null} 无返回值
     */
    owner.goto = function () {
        this.$router.push.apply(this.$router, arguments);
    };

    /**
     * 切除日期中的时分秒
     * @param  {String} e 日期字符串
     * @return {String}   切分后的年月日
     */
    owner.timeToDate = function (e) {
        return timeToDate(e);
    };

    /**
    * 生成表单校验规则
    * @return {Object} 生成的校验规则
    */
    owner.newRule = function () {
        let arg = [], rules = [];
        arg = Array.prototype.slice.apply(arguments);
        let label = arg.splice(0, 1),
            changeState = false;

        const typeMap = {
            string: '字符串',
            number: '数字',
            boolean: '布尔',
            method: '方法',
            regexp: '正则',
            integer: 'integer',
            float: '浮点数',
            array: '数组',
            object: '对象',
            enum: 'enum',
            date: '日期',
            url: 'url地址',
            hex: '哈希',
            email: '电子邮件',
            // ucc: '社会统一信用代码',
        }

        arg.forEach(function (item) {
            if (item === 'change') {
                changeState = true;
            }

            if (item === 'required') {
                rules.push.apply(rules, [{
                    required: true,
                    message: '请输入' + label,
                    trigger: ['blur']
                }, {
                    validator(rules, value, callback) {
                        if (typeof value === 'string') {
                            if (!value.replace(/^\s+|\s+$/g, "")) {
                                return callback(new Error('内容不能为空格'))
                            }
                        }
                        callback();
                    },
                    trigger: ['blur']
                }])
            };

            if (/min/.test(item)) {
                let length = item.split('min')[1];
                rules.push({
                    min: parseInt(length),
                    message: '至少输入' + length + '个字符',
                    trigger: 'blur'
                });
            };

            if (/max/.test(item)) {
                let length = item.split('max')[1];
                rules.push({
                    max: parseInt(length),
                    message: '至多输入' + length + '个字符',
                    trigger: ['blur']
                });
            };

            if (item === 'mobile') {
                rules.push({
                    validator(rules, value, callback) {
                        if (!/^[1][0-9]{10}$/.test(value)) {
                            return callback(new Error('手机号格式错误'));
                        }
                        callback();
                    },
                    trigger: ['blur']
                })
            };

            if (item === 'phone') {
                rules.push({
                    validator(rules, value, callback) {
                        if (!/^[1][0-9]{10}$|^0\d{2,3}-?\d{7,8}$|^400[016789]\d{6}$|^400-[016789]\d{2}-\d{4}$/.test(value)) {
                            return callback(new Error('号码格式错误'));
                        }
                        callback();
                    },
                    trigger: ['blur']
                })
            };

            if (item === 'ucc') {
                rules.push({
                    validator(rules, value, callback) {
                        if (!value) {
                            callback();
                            return;
                        }

                        var patrn = /^[0-9A-Z]+$/;

                        if ((value.length != 18) || !patrn.test(value)) {
                            return callback(new Error('不是有效的统一社会信用代码'));
                        } else {
                            var Ancode;//统一社会信用代码的每一个值
                            var Ancodevalue;//统一社会信用代码每一个值的权重 
                            var total = 0;
                            var weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28];//加权因子 
                            var str = '0123456789ABCDEFGHJKLMNPQRTUWXY';

                            //不用I、O、S、V、Z 
                            for (var i = 0; i < value.length - 1; i++) {
                                Ancode = value.substring(i, i + 1);
                                Ancodevalue = str.indexOf(Ancode);
                                total = total + Ancodevalue * weightedfactors[i];
                                //权重与加权因子相乘之和 
                            }
                            var logiccheckcode = 31 - total % 31;
                            if (logiccheckcode == 31) {
                                logiccheckcode = 0;
                            }
                            var Str = "0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y";
                            var Array_Str = Str.split(',');
                            logiccheckcode = Array_Str[logiccheckcode];


                            var checkcode = value.substring(17, 18);
                            if (logiccheckcode != checkcode) {
                                return callback(new Error('不是有效的统一社会信用代码'));
                            };
                        };

                        callback();
                    },
                    trigger: ['blur', 'change']
                })
            };

            if (item === 'idcard') {
                rules.push({
                    validator(rules, value, callback) {
                        if (
                            value
                            && !/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(value)
                        ) {
                            callback(new Error('不是有效的身份证号'));

                            return;
                        }

                        callback();
                    },
                    trigger: ['blur', 'change']
                })
            };


            Object.keys(typeMap).forEach(function (key, index) {
                if (item === key) {
                    rules.push({
                        type: key,
                        message: '请输入正确的' + typeMap[key] + '格式',
                        trigger: ['blur']
                    })
                }
            });
        });

        if (changeState) {
            rules.forEach((item) => {
                item.trigger.push('change');
            })
        }

        return rules
    }

    /**
     * 获取客户端下载地址
     */
    owner.getClientDownloadUrl = function (type = 'exe') {
        var url = CONFIG_PROJECT.downloadUrl[type].url,
            ssbRoute = this.getQuery('ssb'),
            sscRoute = this.getQuery('ssc'),
            ssbStore = '',
            sscStore = '';

        if (process.browser) {
            ssbStore = storage.getLocal('ssb');
            sscStore = storage.getLocal('ssc');
        }

        if (ssbRoute || ssbStore) {
            url += `&ssb=${ssbRoute || ssbStore}`;
        }

        if (sscRoute || sscStore) {
            url += `&ssc=${sscRoute || sscStore}`;
        }

        return url;
    }


    /**
     * 生成文件预览地址
     * @param  {String} fileurl 文件地址，应为外网可访问的地址
     * @param  {String} watermark 水印内容，默认为空
     * @param  {Boolean} autoOpen 是否自动打开页签，默认为否
     * @return {String}     预览地址
     * 示例：filePreview('http://www.bhtong.cn/bhtong/bhtong_client_bht/202301/91ef728fe3c142ab9e0a4bb9fa64d684.png')
     */
    owner.filePreview = function (fileurl, watermark = '', autoOpen = false) {
        var reEncodeUrl = unescape(encodeURIComponent(fileurl)),
            url = btoa(reEncodeUrl),
            previewUrl = `http://fileview.zhbykj.com/onlinePreview?url=${url}`;

        if (watermark) {
            // 水印信息
            previewUrl = previewUrl + `&watermarkTxt=${watermark}`
        }

        if (autoOpen) {
            // 新开页面
            window.open(previewUrl);
        }

        return previewUrl;
    }

})(Vue.prototype)
