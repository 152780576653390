// 检查设备，如果是移动端，则自动跳转至移动端域名

const MOBILE_REG = /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/ig;

export default function (context) {
    var { req, redirect } = context,
        userAgent;

    if (process.browser) {
        // 客户端
        userAgent = window.navigator.userAgent;
    } else {
        // 服务端
        userAgent = req.headers['user-agent'];
    }

    if (!MOBILE_REG.test(userAgent)) {
        // 非手机客户端
        return;
    }

    redirect('http://wap.huitoue.com');
}
