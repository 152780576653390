export default {
    methods: {
        // 两个浮点数相乘
        accMul(num1, num2) {
            var m = 0,
                s1 = num1.toString(),
                s2 = num2.toString();
            try {
                m += s1.split(".")[1].length;
            } catch (e) {}
            try {
                m += s2.split(".")[1].length;
            } catch (e) {}
            return (
                (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
                Math.pow(10, m)
            );
        },
        //两个浮点数相除
        accDiv(num1, num2) {
            var baseNum1 = 0,
                baseNum2 = 0;
            var baseNum3, baseNum4;

            try {
                baseNum1 = num1.toString().split(".")[1].length;
            } catch (e) {
                baseNum1 = 0;
            }

            try {
                baseNum2 = num2.toString().split(".")[1].length;
            } catch (e) {
                baseNum2 = 0;
            }

            baseNum3 = Number(num1.toString().replace(".", ""));
            baseNum4 = Number(num2.toString().replace(".", ""));

            return (baseNum3 / baseNum4) * Math.pow(10, baseNum2 - baseNum1);
        },
        //两个浮点数求和
        accAdd(num1, num2) {
            var r1, r2, m;
            try {
                r1 = num1.toString().split(".")[1].length;
            } catch (e) {
                r1 = 0;
            }
            try {
                r2 = num2.toString().split(".")[1].length;
            } catch (e) {
                r2 = 0;
            }
            m = Math.pow(10, Math.max(r1, r2));
            // return (num1*m+num2*m)/m;
            return Math.round(num1 * m + num2 * m) / m;
        },
        // 两个浮点数相减
        accSub(num1, num2) {
            var r1, r2, m;
            try {
                r1 = num1.toString().split(".")[1].length;
            } catch (e) {
                r1 = 0;
            }
            try {
                r2 = num2.toString().split(".")[1].length;
            } catch (e) {
                r2 = 0;
            }
            m = Math.pow(10, Math.max(r1, r2));
            let n = r1 >= r2 ? r1 : r2;
            return (Math.round(num1 * m - num2 * m) / m).toFixed(n);
        },

        /**
         * 强制保留2位小数
         * @param {String,Number} x
         * @returns {String} s
         */
        toDecimal2(x) {
            var f = parseFloat(x);

            if (isNaN(f)) {
                return false;
            }

            var f = Math.round(x * 100) / 100;
            var s = f.toString();
            var rs = s.indexOf(".");

            if (rs < 0) {
                rs = s.length;
                s += ".";
            }
            while (s.length <= rs + 2) {
                s += "0";
            }

            return s;
        },
    },
};
