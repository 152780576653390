<template>
    <div class="layout-client">
        <sys-header class="page-header"></sys-header>
        
        <div class="page-body">
            <slot>
                <nuxt />
            </slot>
        </div>

        <el-backtop 
            class="backtop-layout"
            target=".page-body"
        ></el-backtop>

        <dialog-login></dialog-login>

        <dialog-supplement></dialog-supplement>

        <dialog-multi-platform></dialog-multi-platform>

        <dialogBindMobile />

        <daily-events></daily-events>

        <dialog-share></dialog-share>

        <dialog-product></dialog-product>

        <cnzz></cnzz>
    </div>
</template>

<script>
import DailyEvents from '@components/sys/daily-events/index.vue';

import DialogLogin from '../pages/login/components/dialog-login';
import DialogSupplement from '../pages/login/components/dialog-supplement.vue';
import SysHeader from '@components/sys/sys-header/index.vue';
import DialogMultiPlatform from '@components/sys/dialog-multi-platform.vue';
import dialogBindMobile from '../pages/login/components/dialog-bind-mobile.vue';
import DialogShare from '@components/sys/dialog-share.vue';
import DialogProduct from '@/pages/user/center/components/dialog-product.vue';

import { mapActions } from 'vuex';

export default {
    name: 'layout-client',
    components: {
        DailyEvents,
        DialogLogin,
        DialogSupplement,
        SysHeader,
        dialogBindMobile,
        DialogMultiPlatform,
        DialogShare,
        DialogProduct,
    },
    data() {
        return {
            
        };
    },
    methods: {
        ...mapActions({
            showLogin: 'global-dialog/showLogin',
        }),
    },
    mounted: function() {
        this.$store.dispatch('server/checkTime');

        if (window.electronApi) {
            var that = this;

            window.electronApi.addEventListener('login', () => {
                // 显示登陆弹窗逻辑代码
                that.showLogin()
            });

            window.electronApi.addEventListener('logout', () => {
                // 退出登录逻辑代码
                showConfirm('是否确认退出登录？', 'warning', () => {
                    that.$store.dispatch('userinfo/logoutHandler');
                });
            });
        }
    }
};
</script>

<style scoped lang="scss">
.layout-client {
    display: flex;
    flex-direction: column;
    background-color: #f5f7fd;
    width: 100%;
    height: 100%;
}

.page-body {
    flex: 1;
    overflow: auto;
}

.backtop-layout{
    
}
</style>
