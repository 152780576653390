const KEY_NAME = 'itemDate';

export default function WxQrLogin(obj) {
    this.init(obj);
}

/**
 * 初始化
 * @param {Object} obj 参数
 * autoListener 自动加载监听事件
 * onscan 扫码成功
 * onscanerror 扫码失败
 * onscancomplete 扫码完成
 */
WxQrLogin.prototype.init = function(obj) {    
    // 设置默认值
    var reObj = {
        ...{
            autoListener: true,
        },
        ...obj,
    };

    this.$settings = obj;

    var {autoListener} = reObj;

    if (autoListener) {
        let {onscan, onscanerror, onscancomplete} = reObj;

        // 初始化监听函数
        this._storageChangeHandler = this._storageChangeHandlerFactory(onscan, onscanerror, onscancomplete);

        // 监听
        this.addListener();
    }
}

// 打开二维码页面
WxQrLogin.prototype.openQrcode = function() {
    window.localStorage.removeItem(KEY_NAME);

    var httptype = window.location.protocol,
        httptype = httptype.slice(0, -1);

    window.open(`${this.$store.state.api.webUrl}/userlogin/wechatlogin.json?httptype=${httptype}`, '_blank');
}

// 关闭二维码页面（二维码页面调用）
WxQrLogin.prototype.closeQrcode = function() {
    var state = getUrlParams('state'),
        code = getUrlParams('code'),
        itemDate = {
            state, 
            code,
            TbezWeChat: 'ok',
        };

    window.localStorage.setItem(KEY_NAME, JSON.stringify(itemDate));
    
    if (navigator.userAgent.indexOf('Firefox') != -1 || navigator.userAgent.indexOf('Chrome') != -1) {
        window.location.href = 'about:blank';
        window.close();
    } else {
        window.opener = null;
        window.open('', '_self', '');
        window.close();
    }
}

/**
 * storage变化监听器
 * @param {Function} loginHandler 扫码成功 
 * @param {Function} errorHandler 扫码失败
 * @param {Function} completeHandler 扫码完成
 * @returns 
 */
WxQrLogin.prototype._storageChangeHandlerFactory = function(loginHandler, errorHandler, completeHandler) {
    return function(e) {
        if (
            e.key === KEY_NAME
            && e.newValue
        ) {
            var storageData = JSON.parse(e.newValue);
    
            if (
                storageData.state 
                && storageData.code
            ) {
                loginHandler && loginHandler(storageData);
            } else {
                window.localStorage.removeItem(KEY_NAME);
                window.showConfirm('用户扫码登录失败，请重新登录');

                errorHandler && errorHandler();
            }
            
            completeHandler && completeHandler;
        }
    }
}

// 添加监听
WxQrLogin.prototype.addListener = function() {
    window.addEventListener('storage', this._storageChangeHandler);
}

// 移除监听
WxQrLogin.prototype.removeListener = function() {
    window.removeEventListener('storage', this._storageChangeHandler);
}

// 销毁实例
WxQrLogin.prototype.destroy = function() {
    if (this.$settings.autoListener) {
        this.removeListener();
    }

    window.localStorage.removeItem(KEY_NAME);

    this.$settings = null;
    this._storageChangeHandler = null;
}

/**
 * 获取 url 中的参数
 * @param {String} paras 参数名
 * @returns 
 */
function getUrlParams(paras) {
    const url = location.href;
    const paraString = url.substring(url.indexOf('?') + 1, url.length).split('&');
    let paraObj = {};
    for (let i of paraString) {
        paraObj[i.substring(0, i.indexOf('=')).toLowerCase()] = i.substring(i.indexOf('=') + 1, i.length);
    }
    if (typeof paraObj == 'undefined') {
        return '';
    } else if (paras) {
        return paraObj[paras.toLowerCase()];
    } else {
        return paraObj;
    }
};