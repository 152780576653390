<template>
    <div class="logo">
        <div class="icon">
            <img 
                src="@/assets/images/logo3.png" 
                :alt="CONFIG_PROJECT.title"
                @click="backToHome"
            />
        </div>

        <div class="slogan">
            让投标工作更简单
        </div>
    </div>
</template>

<script>
import CONFIG_PROJECT from '@config/project';

export default {
    data() {
        return {
            CONFIG_PROJECT,
        };
    },
    methods: {
        backToHome() {
            this.goto('/home');
        },
    },
};
</script>

<style lang="scss" scoped>
    .logo{
        display: flex;
        align-items: center;

        .icon{

            img {
                display: block;
                height: 38px;
                cursor: pointer;
            }
        }

        .slogan{
            color: white;
            font-size: 16px;
            margin-left: 1em;
            cursor: default;
        }
    }

    @media screen and (max-width:1220px) {
        .logo{
            .slogan{
                margin-left: 8px;
                font-size: 15px;
            }
        }
    }
</style>