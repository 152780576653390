import CryptoJS from "crypto-js";

/**
 * 请求接口拦截：
 * - 同时发生多次相同请求
 * - 节流
 */

const _WAITINT_LIST = [], // 请求中的列表
    _HASH_LIST = []; // 请求中的hash列表

const _TIMEOUT_TIME = 300; // 请求成功后若干时间内，不允许重复的请求

class ReqItem {
    constructor({
        method = "get",
        url,
        data = "",
        callback = () => {},
        error = () => {},
        complete = () => {},
    }) {
        this.method = method; // 请求方法
        this.url = url; // 请求地址
        this.data = data; // 请求数据

        this.hash = null; // hash值
        this.ts = null; // 时间戳

        this.callbackList = [];
        this.errorList = [];
        this.completeList = [];

        this.updateTs();
        this.updateHash();
        this.addCallbacks({ callback, error, complete });
    }

    // 更新hash
    updateHash() {
        let dataStr;

        if (typeof this.data === "string") {
            dataStr = this.data;
        } else {
            dataStr = JSON.stringify(this.data);
        }

        let hash = CryptoJS.MD5(this.method + this.url + dataStr).toString();

        this.hash = hash;
    }

    // 更新时间戳
    updateTs() {
        this.ts = new Date().getTime();
    }

    addCallbacks({ callback, error, complete }) {
        if (callback) {
            this.callbackList.push(callback);
        }

        if (error) {
            this.errorList.push(error);
        }

        if (complete) {
            this.completeList.push(complete);
        }
    }

    // 请求成功
    callback([data, res]) {
        this.callbackList.forEach((fun) => {
            fun && fun(data, res);
        });
    }

    // 请求失败
    error(err) {
        this.errorList.forEach((fun) => {
            fun && fun(err);
        });
    }

    // 请求完毕
    complete() {
        this.completeList.forEach((fun) => {
            fun && fun();
        });
    }
}

var requestControl = {
    // 增加一个控制项
    add({ method, url, data, callback, error, complete }) {
        let rItem = new ReqItem({
                method,
                url,
                data,
                callback,
                error,
                complete,
            }),
            index = _HASH_LIST.findIndex((item) => item === rItem.hash);

        if (index > -1) {
            _WAITINT_LIST[index].addCallbacks({ callback, error, complete });

            return false;
        }

        _WAITINT_LIST.push(rItem);
        _HASH_LIST.push(rItem.hash);

        return rItem;
    },

    // 移除一个控制项
    remove(rItem, beforeRemove) {
        setTimeout(() => {
            var index = _HASH_LIST.findIndex((item) => item === rItem.hash);

            if (index === -1) {
                // 不存在
                return;
            }

            beforeRemove && beforeRemove(index);

            _WAITINT_LIST.splice(index, 1);
            _HASH_LIST.splice(index, 1);
        }, _TIMEOUT_TIME);
    },

    // 清除
    clear() {
        _WAITINT_LIST = [];
        _HASH_LIST = [];
    },
};

// if (process.client) {
//     window._WAITINT_LIST = _WAITINT_LIST;
// }

export default requestControl;
