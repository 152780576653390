<template>
    <div class="user">
        <client-only>
            <!-- <el-badge 
            class="badge-msg"
            :value="messageTotal" 
            :max="99"
            :hidden="!messageTotal"
            >
                <div 
                v-if="nickname" 
                class="userbtns"
                @click="goto('/user/message')"
                >
                    <i 
                    class="iconfont btn btn-msg"
                    :class="{active: messageTotal}"
                    >
                        &#xe65f;
                    </i>
                </div>
            </el-badge> -->
            
            <div class="userinfo">
                <template
                v-if="nickname" 
                >
                    <el-dropdown 
                    class="header-dropdown"
                    @command="commandHandler"
                    >
                        <!-- <el-image
                            class="user-header"
                            :src="headportraitpath"
                            fit="cover"
                        ></el-image> -->

                        <div class="username">
                            {{nickname}}

                            <i class="el-icon-caret-bottom"></i>
                        </div>

                        <el-dropdown-menu 
                        slot="dropdown"
                        class="user-dropdown-menu"
                        >
                            <!-- <div class="user-info">
                                <adduser-info
                                    size="small"
                                    :data="userDetail"
                                ></adduser-info>
                            </div> -->

                            <el-dropdown-item 
                            v-for="(item, index) in dropdownArr"
                            :key="index"
                            :command="item.command"
                            :divided="item.divided"
                            >
                                <i class="iconfont" v-html="item.icon"></i>

                                {{item.text}}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>

                <template
                v-else
                >
                    <div 
                    class="btn-group" 
                    >
                        <div class="btn-item" @click="loginHandler">
                            注册/登录
                        </div>
                    </div>
                </template>
            </div>

        </client-only>
    </div>
</template>

<script>
import AdduserInfo from '../../adduser-info';

import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        AdduserInfo,
    },
    data() {
        return {
            dropdownArr: [
                {
                    command: 'user',
                    icon: '&#xe61c;',
                    text: '新个人中心'
                },
                {
                    command: 'oldUser',
                    icon: '&#xe61c;',
                    text: '旧个人中心'
                },
                {
                    divided: true,
                    command: 'logout',
                    icon: '&#xe621;',
                    text: '退出登录'
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            userInfo: 'userinfo/getData',
            nickname: 'userinfo/getNickname',
            headportraitpath: 'userinfo/getHeadportraitpath',
            messageTotal: 'message/getTotal'
        }),
        userDetail() {
            return {
                head: this.userInfo.headportraitpath,
                nickname: this.userInfo.nickname,
            };
        },
    },
    methods: {
        commandHandler(command) {
            var switchObj = {
                user: () => {
                    this.goto(this.$store.state.defaultUserPage);
                },
                collect: () => {
                    this.goto('/user/collect');
                },
                logout: () => {
                    this.$store.dispatch('userinfo/logoutHandler');
                },
                oldUser: () => {
                    this.goto('/user/application/business-inquiries');
                }
            };

            switchObj[command]();
        },
        ...mapActions({
            queryUserInfo: 'userinfo/queryUserInfo',
            queryMsgNum: 'message/queryMsgNum',
            showLogin: 'global-dialog/showLogin',
        }),
        loginHandler() {
            this.showLogin(() => {
                this.goto(this.$store.state.defaultUserPage);
            });
        }
    },
    mounted: function() {
        this.queryUserInfo();
        this.queryMsgNum();    
    },
};
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .user{
        float: right;
        margin-top: 28px;

        &:after{
            content: '';
            clear: both;
        }

        .badge-msg{
            ::v-deep {
                .is-fixed{
                    top: -4px;
                }
            }
        }

        .userbtns{
            float: left;
            color: #999999;
            margin-top: -4px;
            
            .btn{
                cursor: pointer;
                font-size: 20px;
                display: inline-block;
                width: 20px;
                height: 20px;

                &.active{
                    color: $primaryColor;
                }
            }
        }

        .userinfo{
            float: right;
            margin-left: 20px;
            margin-top: -10px;
        }

        .btn-group{
            overflow: hidden;

            .btn-item{
                display: block;
                float: left;
                line-height: 1em;
                color: $primaryColor;
                border: 1px solid $primaryColor;
                border-radius: 4px;
                position: relative;
                transition: all .3s;
                padding: 10px 18px;
                cursor: pointer;

                &:hover{
                    color: white;
                    background-color: $primaryColor;
                }
            }
        }

        .header-dropdown{
            .user-header{
                width: 34px;
                height: 34px;
                background: $primaryColor1;
                border-radius: 50%;
                transition: all .3s;
                vertical-align: middle;

                &:hover{
                    box-shadow: -3px 3px 3px #DCDFE6;
                }
            }

            .username{
                font-size: 16px;
                line-height: 34px;
            }
        }
    }

    .user-dropdown-menu{
        width: 180px;

        .user-info{
            padding: 0 20px;
            margin-bottom: 10px;
        }

        .el-dropdown-menu__item{
            text-align: center;
        }
    }
</style>