import MyHeader from './sys/my-header/index';
import MyFooter from './sys/my-footer';
import InputMobileYzm from './sys/input-mobile-yzm';

export default{
    install: function(Vue){
        Vue.component('my-header', MyHeader);
        Vue.component('my-footer', MyFooter);
        Vue.component('input-mobile-yzm', InputMobileYzm);
    }
}
