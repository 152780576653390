<template>
    <div class="pay-body">
        <div class="row price" v-if="money">
            <span class="">
                实际支付:
                <div class="money">{{ `￥${money * num}` }}元</div>
            </span>
            <span>请使用微信或支付宝支付</span>
        </div>

        <div class="row qrcode pay-type">
            <div class="code-item wechat">
                <div class="code">
                    <i v-if="!wxpaySrc" class="el-icon-loading"></i>
                    <img v-if="wxpaySrc" :src="wxpaySrc" alt="微信支付二维码" />
                </div>

                <div class="code-name">
                    <img
                        class="icon"
                        src="@images/icon/icon-wxpay.png"
                        alt="微信支付"
                    />
                    微信支付
                </div>
            </div>

            <div class="code-item alipay">
                <div class="code">
                    <i v-if="!alipaySrc" class="el-icon-loading"></i>
                    <img
                        v-if="alipaySrc"
                        :src="alipaySrc"
                        alt="阿里支付二维码"
                    />
                </div>

                <div class="code-name">
                    <img
                        class="icon"
                        src="@images/icon/icon-alipay.png"
                        alt="支付宝支付"
                    />
                    支付宝支付
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcode';

export default {
    props: {
        successDialog: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            money: 0,
            num: 1,
            ordercode: '',

            wxpaySrc: '',
            alipaySrc: '',

            pollingLock: false,
            pollingTimer: null,

            vipInfo: false,
        };
    },
    methods: {
        // 创建订单
        initHandler(data) {
            this.money = data.realamount;
            this.num = data.productnum;

            this.$post(
                `${this.$store.state.api.sysUrl}/pay/allinpay/createorderandpay.json`,
                data,
                (data) => {
                    QRCode.toDataURL(data.wxpayinfo, (err, src) => {
                        if (err) {
                            console.error(err);
                        }

                        src && (this.wxpaySrc = src);
                    });

                    QRCode.toDataURL(data.zfbpayinfo, (err, src) => {
                        if (err) {
                            console.error(err);
                        }

                        src && (this.alipaySrc = src);
                    });

                    this.setVipInfo(data);

                    this.ordercode = data.fictitiousid;

                    this.pollingHandler();
                }
            );
        },
        // 重新支付
        rePayHandler(data) {
            this.money = data.payamount;

            this.$post(
                `${this.$store.state.api.sysUrl}/pay/allinpay/topay.json`,
                data,
                (data) => {
                    QRCode.toDataURL(data.wxpayinfo, (err, src) => {
                        src && (this.wxpaySrc = src);
                    });

                    QRCode.toDataURL(data.zfbpayinfo, (err, src) => {
                        src && (this.alipaySrc = src);
                    });

                    this.setVipInfo(data);

                    this.ordercode = data.fictitiousid;

                    this.pollingHandler();
                }
            );
        },
        // 轮询查结果
        pollingHandler() {
            function polling(cb) {
                this.$get(
                    `${this.$store.state.api.sysUrl}/pay/allinpay/paystatus.json`,
                    {
                        ordercode: this.ordercode,
                    },
                    (data) => {
                        // 0待支付，1支付成功，2支付失败，3订单失效，4验签失败
                        if (this.pollingLock) return;

                        if (data === '200') {
                            if (this.successDialog) {
                                showMsgBox('支付成功', 'success');
                            }

                            this.$emit('success');
                        } else {
                            this.pollingTimer = setTimeout(() => {
                                polling.call(this);
                            }, 3000);
                        }
                    }
                );
            }

            polling.call(this, polling);
        },
        // 设置vip信息
        setVipInfo(data) {
            if (data.vip === 'yes') {
                this.vipInfo = {
                    vipstarttime: new Date(data.vipstarttime).pattern(
                        'yyyy-MM-dd HH:mm:ss'
                    ),
                    vipendtime: new Date(data.vipendtime).pattern(
                        'yyyy-MM-dd HH:mm:ss'
                    ),
                };
            } else {
                this.vipInfo = false;
            }
        },
    },
    beforeDestroy() {
        this.pollingLock = true;

        try {
            clearTimeout(this.pollingTimer);
        } catch (e) {
            // e
        }

        this.wxpaySrc = this.alipaySrc = '';
        this.vipInfo = false;
    },
};
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

.pay-body {
    margin-top: -30px;

    .row {
        text-align: center;

        & + .row {
            // margin-top: 45px;
        }

        &.pay-type {
            padding: 25px 0;
        }

        &.vipinfo {
            margin-top: 25px;
            text-align: left;
            // color: $primaryColor;
        }

        &.price {
            font-size: 16px;
            font-weight: bold;
            text-align: center;

            span {
                display: flex;
                margin-top: 5px;
                justify-content: center;
            }

            .money {
                color: red;
            }
        }

        &.qrcode {
            overflow: hidden;
            width: 500px;
            margin-left: auto;
            margin-right: auto;

            .code-item {
                width: 50%;
                float: left;

                &.wechat {
                    .code {
                        border: 1px solid #61bd32;
                    }
                }

                &.alipay {
                    .code {
                        border: 1px solid #22b5f0;
                    }
                }

                .code {
                    width: 138px;
                    height: 138px;
                    border-radius: 6px;
                    margin: 0 auto;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .el-icon-loading {
                        line-height: 138px;
                        font-size: 20px;
                    }
                }

                .code-name {
                    line-height: 1em;
                    margin-top: 15px;

                    .icon {
                        vertical-align: middle;
                    }
                }
            }
        }

        &.clause {
            text-align: left;
            // margin-bottom: -10px;
            margin-bottom: 20px;
        }
    }

    .transfer {
        .top-tip {
            margin-top: -20px;
            margin-bottom: 30px;
            text-align: left;

            .tel {
                color: $primaryColor;
            }
        }

        .trans-info {
            font-size: 16px;

            .info-row {
                & + .info-row {
                    margin-top: 6px;
                }

                .label {
                    width: 200px;
                    margin-right: -200px;
                    float: left;
                    text-align: left;
                }

                .value {
                    margin-left: 200px;
                    text-align: left;
                    color: #888;
                }
            }
        }

        .tip {
            text-align: left;
            margin-top: 35px;
        }
    }
}
</style>