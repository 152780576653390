const IS_DEV = process.env.NODE_ENV === 'development'; // 开发环境

export default {
    title: '投标E站',
    // 下载地址
    downloadUrl: {
        exe: {
            // url: '/download/client/latist/投标E站 Setup.exe',
            // url: 'https://tbez.oss-cn-beijing.aliyuncs.com/Setup.exe',
            url: '/tbez/download/getFile?type=exe', // 使用的是/Setup.exe
            filename: '投标E站 Setup.exe',
        },
        zip: {
            // url: '/download/client/latist/win-ia32-unpacked.zip',
            // url: 'https://tbez.oss-cn-beijing.aliyuncs.com/win-ia32-unpacked.zip',
            url: '/tbez/download/getFile?type=zip',
            filename: '投标E站 unpacked.zip',
        },
    },
    // 相关域名
    origins: {
        www: !IS_DEV
                ? 'http://www.huitoue.com'
                : 'http://123.182.227.105:12015',
        wxmpQrcode: 'https://www.huitoue.com/mp', // 微信小程序扫码
        // h5端域名/地址
        wap: !IS_DEV
                ? 'http://wap.huitoue.com/#'
                : 'http://123.182.227.105:12017/#',
    },
};