


export default function (context)  {
    if(!process.browser) {
        return;
    }

    if (!window.electronApi) {
        return;
    }

    var {app} = context;

    const MSG_URL = '/tbez/sendmsg/getmsg.json';

    var mac = window.electronApi.mac;

    function loopRequest() {
        app.$axios.get(MSG_URL, {
            params: {
                mac
            },
        }).then(([data, res]) => {
            if (data) {
                window.electronApi.modalNotify(data);
            }

            // 轮询，每分钟请求一次
            setTimeout(() => {
                loopRequest();
            }, 60000);
        });
    }

    loopRequest();
}