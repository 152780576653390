<template>
    <transition name="el-fade-in-linear">
        <div 
        v-if="model"
        ref="dCustom"
        class="modal-custom"
        :class="{ 'no-bg': noModal }" 
        >
            <!-- 弹窗主体 -->
            <div 
            class="dialog-custom" 
            :style="{width: `${width}px`}"
            >
                <div class="head">
                    <div class="banner">
                        <img :src="img" alt="" class="img">

                        <div class="text" :style="{top: `${contextTop}px`}">
                            {{context}}
                        </div>
                    </div>

                    <div class="btn-close" @click="close"></div>
                </div>

                <div class="body">
                    <slot>
                        <div v-if="tips" class="body-tips">
                            {{tips}}
                        </div>

                        <div class="btn" @click="clickBtn">
                            {{btn}}
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </transition>
</template>


<script>
import MIXIN_MODEL from '@mixins/model.js';

import dialogWaitList from '@js/dialog-wait-list.js';

export default {
    mixins: [MIXIN_MODEL],
    props: {
        // 控制展示
        value: {
            type: Boolean,
            default: false,
        },
        //是否显示 背景
        noModal: {
            type: Boolean,
            default: false,
        },
        //弹窗主体 宽度
        width: {
            type: String,
            default: '712',
        },
        // 背景图片类型
        theme: {
            type: String,
            default: 'login',
        },
        //图片文字 margin-top
        contextTop: {
            type: String,
            default: '158',
        },
        // 主题提示内容
        context: {
            type: String,
            default: '无需注册，登录即可领取百元福利邀请好友登录，福利翻倍',
        },
        // body 提示
        tips: {
            type: String,
            default: '',
        },        
        //默认弹窗按钮
        btn: {
            type: String,
            default: '立即领取',
        },
    },
    data() {
        return {
            
        };
    },
    mounted() {},
    computed: {
        img() {
            var switchObj = {
                login: '/images/daily-events/login.png',
                share: '/images/share/share.png',
            };

            return switchObj[this.theme];
        },
    },
    methods: {
        close(next) {
            this.model = false;

            this.$emit('close');

            // 继续执行列队中的其他节点
            dialogWaitList.next();
        },

        clickBtn() {
            this.model = false;

            this.$emit('close');

            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-custom {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    &.no-bg {
        background: none;
    }

    .dialog-custom {
        background: white;
        border-radius: 6px;
        position: fixed;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        overflow: hidden;

        .head {
            position: relative;

            .banner {
                height: 344px;
                
                .img {
                    display: block;
                    width: 100%;
                }

                .text {
                    margin: 0 auto;
                    width: 400px;
                    box-sizing: content-box;
                    color: #bc0000;
                    font-size: 26px;
                    padding: 5px;
                    line-height: 1.5em;
                    font-weight: bold;
                    text-align: center;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 99;
                }
            }

            .btn-close {
                position: absolute;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                right: 20px;
                top: 24px;
                box-sizing: border-box;
                cursor: pointer;

                &:before,
                &:after {
                    content: '';
                    display: bolck;
                    width: 2px;
                    height: 18px;
                    background: white;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    border-radius: 1px;
                }

                &:before {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }

                &:after {
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &:hover:before,
                &:hover:after {
                    background: #050070;
                }
            }

            .btn-close {
                position: absolute;
                right: 5px;
                top: 5px;
            }
        }

        .body {
            padding: 20px 20px 25px;

            .body-tips {
                color: #603200;
                font-size: 17px;
                margin: 0 auto 20px;
                text-align: center;
            }

            .btn {
                width: 280px;
                height: 74px;
                margin: 0 auto;
                border-radius: 37px;
                background: linear-gradient(to bottom, #ff8541, #f9493d) #ff8541;
                box-shadow: 0 5px 0 #c80000;
                cursor: pointer;
                font-size: 26px;
                color: #fff;
                text-align: center;
                line-height: 74px;

                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }
}
</style>
