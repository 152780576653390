<template>
    <div 
    class="userinfo"
    :class="size"
    >
        <el-image 
            class="head" 
            :src="head"
            fit="cover"
        ></el-image>

        <div class="text">
            <div class="name">
                <div class="username">
                    {{name}}
                </div>

                <slot name="designation">
                    <div 
                    class="designation" 
                    v-if="description"
                    >
                        {{description}}
                    </div>
                </slot>
            </div>
        </div>

        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        size: {
            type: String,
            default: 'normal',
            //small
        },
        userhead: {
            type: String,
            default: '',
        },
        username: {
            type: String,
            default: '',
        },
    },
    computed: {
        head() {
            if (this.userhead) {
                return this.userhead;
            }

            return this.data.head || '/upload/headportrait/2021-08/19/headportrait.jpg'
        },
        name() {
            if (this.username) {
                return this.username;
            }

            return this.data.name || this.data.addusername || this.data.nickname;
        },
        description() {
            var marklist = this.data.marklist || [],
                firstmark = marklist[0] || {};

            return firstmark.description || '';
        }
    },
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .userinfo{
        overflow: hidden;

        &.normal {
            $headSize: 42px;
            $textSize1: 16px;
            $textSize2: 14px;

            .head{
                width: $headSize;
                height: $headSize;
            }

            .text{
                height: $headSize;
            }

            .name{
                .username {
                    font-size: $textSize1;
                    color: #333;
                }

                .designation {
                    font-size: $textSize2;
                }
            }
        }

        &.small {
            $headSize: 36px;
            $textSize1: 12px;
            $textSize2: 12px;

            .head{
                width: $headSize;
                height: $headSize;
            }

            .text{
                height: $headSize;
            }

            .name{
                .username {
                    font-size: $textSize1;
                    color: #666;
                }

                .designation {
                    font-size: $textSize2;
                }
            }
        }

        .head{
            background-color: $primaryColor1;
            position: relative;
            border-radius: 50%;
            overflow: hidden;
            float: left;
            margin-right: -50px;
        }

        .text{
            position: relative;
            margin-left: 50px;
        }

        .name{
            line-height: 1em;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            .designation {
                color: #666666;
                margin-top: 8px;
            }
        }
    }
</style>