<template>
    <div class="ip">
        <div class="label" @click="queryData">
            <i 
                v-if="loadingController"
                class="el-icon-loading"
            ></i>

            {{text}}
        </div>

        <!-- <div 
        class="value" 
        :class="{active: request}"
        >
            
        </div> -->
    </div>
</template>

<script>

export default {
    data() {
        return {
            text: 'ip地址排查',
            
            request: false,
            loadingController: false,
        };
    },
    methods: {
        queryData() {
            if (this.loadingController) {
                // 请求中
                return;
            }

            if (this.request) {
                // 已请求
                return;
            }

            this.loadingController = true;

            $.ajax({
                url: 'https://api.ipify.org/?format=json', 
                success: data=> {
                    this.text = data.ip;

                    this.request = true;
                },
                complete: () => {
                    this.loadingController = false;
                },
            });
        },
    },
    mounted() {
        
    }
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .ip{
        float: right;
        margin-top: 18px;
        margin-left: 70px;
        overflow: hidden;
        line-height: 1em;
        
        .label{
            display: block;
            float: left;
            line-height: 1em;
            background: $primaryColor;
            color: white;
            border: 1px solid $primaryColor;
            border-radius: 4px;
            position: relative;
            transition: all .3s;
            padding: 10px 18px;
            cursor: pointer;

            &:hover{
                background: $primaryColor8;
            }
        }

        .value{
            float: left;
            padding: 9px 1em;
            border: 1px solid $primaryColor;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            color: $infoColor;
            cursor: pointer;
            width: 200px;
            text-align: center;

            &.active{
                cursor: default;
            }
        }
    }
</style>