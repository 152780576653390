<template>
    <my-dialog v-model="dialogVisible" width="680px" title="确认支付">
        <Pay ref="pay" v-if="dialogVisible" @success="onPaySuccess"></Pay>
    </my-dialog>
</template>

<script>
import Pay from './pay.vue';

export default {
    components: {
        Pay,
    },
    data() {
        return {
            dialogVisible: false,
        };
    },
    watch: {
        
    },
    methods: {
        // 创建订单
        initHandler(data) {
            this.dialogVisible = true;

            this.$nextTick(() => {
                this.$refs.pay.initHandler(data);
            });
        },
        // 重新支付
        rePayHandler(data) {
            this.dialogVisible = true;

            this.$nextTick(() => {
                this.$refs.pay.rePayHandler(data);
            });
        },
        // 关闭弹窗
        dialogClose() {
            this.dialogVisible = false;
        },
        onPaySuccess() {
            this.$emit('success');
            this.dialogClose();
        },
    },
};
</script>

<style lang="scss" scoped>

</style>