<template>
    <transition name="el-fade-in-linear">
        <dialog-model v-if="visible">
            <div class="dialog-sign">
                <div class="head">
                    <div class="banner">
                        <div class="text">
                            {{signTitle}}
                        </div>
                    </div>

                    <div 
                        class="btn-close"
                        @click="close"
                    ></div>
                </div>

                <div class="body">
                    <div 
                    v-for="(item, index) in data"
                    :key="index"
                    class="item"
                    :class="item.class"
                    >
                        <div class="title">
                            <div class="index">
                                {{index + 1}}
                            </div>

                            <div class="text">
                                {{item.title}}
                            </div>

                            <div class="bar" v-if="item.type === 'full'">
                                <div class="process">
                                    <el-progress 
                                        :percentage="item.percentage"
                                        :show-text="false"
                                    ></el-progress>
                                </div>

                                <div class="process-text">
                                    {{item.num}}
                                    /
                                    {{item.total}}
                                </div>
                            </div>
                        </div>

                        <div class="desc">
                            <!-- {{item.desc}} -->
                            <div class="desc-item" v-for="(item2,index2) in item.products" :key="index2">
                                <div class="title">
                                    {{item2.detail}} 
                                    <span>{{item2.extdetail}}</span>
                                </div>

                                <div class="use-btn" @click="goUse(item2)">去使用</div>
                            </div>
                        </div>

                        <img 
                            class="icon-get"
                            :src="item.iconGet"
                        />
                    </div>

                    <div class="tip-desc">
                        当天签到所得次数请在24点前使用，过期清零
                    </div>
                </div>

                <div class="footer">
                    <div 
                    v-if="loginStatus && completedSettingInfo !== '1'"
                    class="row"
                    >
                        <div class="text">
                            {{settingInfoRule}}
                        </div>

                        <el-button 
                        type="primary" 
                        size="small" 
                        @click="completeInfo"
                        v-czc="['每日签到', '点击按钮' , '补充信息' ,0 , `daily-sign-btn-complete-info`]"
                        >
                            补充信息
                        </el-button>
                    </div>

                    <div 
                    v-if="loginStatus && inviteCodeRule"
                    class="row"
                    >
                        <div class="text">
                            {{inviteCodeRule.title}}
                        </div>

                        <el-button 
                        type="primary" 
                        size="small" 
                        @click="shareCopyHandler"
                        v-czc="['每日签到', '点击按钮' , '复制邀请码' ,0 , `daily-sign-btn-copy-invitecode`]"
                        >
                            复制邀请码
                        </el-button>
                    </div>

                    <div 
                    class="row"
                    >
                        <div class="text">
                            分享{{CONFIG_PROJECT.title}}给您身边的朋友，注册成功后双方均可获得五次投标方案模板下载。
                        </div>

                        <el-button 
                        type="primary" 
                        size="small" 
                        @click="shareClickHandler"
                        v-czc="['每日签到', '点击按钮' , '分享' ,0 , `daily-sign-btn-share`]"
                        >
                            <i class="el-icon-share"></i>
                            分享
                        </el-button>
                    </div>

                    <div 
                    v-if="!loginStatus"
                    class="row no-login"
                    >
                        <el-button type="primary" @click="gotoLogin">立即登录</el-button>
                    </div>
                </div>
            </div>
        </dialog-model>
    </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import app from '@js/app-node';
import dialogWaitList from '@js/dialog-wait-list.js';
import CONFIG_PROJECT from '@config/project';

import dialogModel from './dialog-model';

export default {
    components: {
        dialogModel,
    },
    data: () => ({
        CONFIG_PROJECT,

        visible: false,
        onclose: null,

        signTitle: '', //签到标题
        data: [], //签到奖励列表
        completedSettingInfo: '1', //已完善信息标记
        settingInfoRule: '', //完成签到规则信息
        inviteCodeRule: false, //邀请码规则
        todayQuestionnaire:null, //调查问卷
        questionnaireRules:{}, //调查问卷规则
        loginStatus: false, //是否登录
        noLoginInRules: [], // 未登录赠送

        chain: null,
    }),
    computed: {
        ...mapGetters({
            nickname: 'userinfo/getNickname',
        }),
        currentDay() {
            return new Date().getDay();
        },
    },
    methods: {
        ...mapActions({
            openGlobalDialog: 'global-dialog/openGlobalDialog',
        }),
        /**
         * 初始化/绑定数据
         */
        initData(data) {
            var {
                    signTitle, //签到标题
                    allRules, //签到规则信息
                    completedSettingInfo, //完成签到标记
                    settingInfoRule,  //完成签到规则信息
                    inviteCodeRule, //邀请码规则
                    todayQuestionnaire, //调查问卷
                    questionnaireRules, //调查问卷规则
                    loginStatus, //是否登录
                    noLoginInRules, // 未登录赠送
                } = data;

            this.signTitle = signTitle;
            this.data = allRules.map(item => {
                if (item.type === 'full') {
                    return {
                        ...item,
                        iconGet: '/images/daily-events/signin-get.png',
                        class: {active: item.get, disabled: item.disabled, [item.type]: true,},
                        percentage: (item.num / item.total) * 100,
                    };
                }

                return {
                    ...item,
                    iconGet: '/images/daily-events/signin-today-get.png',
                    class: {active: item.get, disabled: item.disabled, [item.type]: true,},
                };
            });

            this.completedSettingInfo = completedSettingInfo;
            this.settingInfoRule = settingInfoRule;
            this.inviteCodeRule = inviteCodeRule;
            this.todayQuestionnaire = todayQuestionnaire;
            this.questionnaireRules = questionnaireRules;
            this.loginStatus = loginStatus;
            this.noLoginInRules = noLoginInRules;
        },
        open(obj) {
            var {data, onclose} = obj;

            this.initData(data);

            this.onclose = onclose || function() {};

            //未登录时，不调用签到接口：tbez/signin/signin.json，判断return
            if(data.loginStatus){
                this.signHandler();
            }
            
            this.visible = true;
        },
        close() {
            var chain = new app.Chain();

            chain.link(next => {
                this.visible = false;

                next();
            });

            chain.link(next => {
                // 调查问卷
                if(this.todayQuestionnaire){
                    // 已完成答卷，无需再弹出问卷
                    next();
                    
                    return;
                }

                if (
                    this.questionnaireRules 
                    && this.questionnaireRules.url
                ) {
                    // 满足问卷调查的时间 且 有问卷调查的地址
                    let {title, desc, url} = this.questionnaireRules;

                    this.$confirm(desc, title, {
                        confirmButtonText: '前往查看',
                        cancelButtonText: '关闭',
                        type: 'info'
                    }).then(() => {
                        window.open(url);

                        next(); 
                    }).catch(() => {
                        next(); 
                    });
                }
            });

            chain.link(next => {
                //连续若干天没有登录
                if (
                    !this.noLoginInRules 
                    || !this.noLoginInRules.length
                ) {
                    next();

                    return;
                }

                var noLoginInRule = this.noLoginInRules[0],
                    { title, desc, url } = noLoginInRule;

                this.$confirm(desc, title, {
                    confirmButtonText: '前往查看',
                    cancelButtonText: '关闭',
                    type: 'info',
                }).then(() => {
                    this.goto(url);
                }).catch(() => {
                    next();
                });
            });

            chain.link(next => {
                if (this.onclose) {
                    this.onclose();
                }

                this.$emit('close');

                // 继续执行列队中的其他节点
                dialogWaitList.next();
            });

            chain.run();
        },
        signHandler() {
            this.$get(`${this.$store.state.api.sysUrl}/signin/signin.json`, data => {
                this.initData(data);

                this.questionnaryHandler(data);
            });
        },
        /**
         * 检查调查问卷
         */
        questionnaryHandler(data) {
            if (data.todayQuestionnaire) {
                // 已经提示过
                return;
            }

            this.questionnaireRules = data.questionnaireRules;
        },
        // 完善信息
        completeInfo() {
            this.openGlobalDialog({
                name: 'supplement',
            });
        },
        shareCopyHandler() {
            var text = this.inviteCodeRule.code;

            this.$copyText(text).then(function (e) {
                showMsgBox('已复制分享信息，请前往粘贴以分享', 'success');
            });
        },
        shareClickHandler() {
            var url = `您的好友邀请您登录${CONFIG_PROJECT.title}，领取五次投标方案模板下载。地址:${CONFIG_PROJECT.origins.www}`;

            if (this.nickName) {
                // 已登录则带上账号信息
                url += `?ssc=${this.nickName}`;
            }

            this.$copyText(url).then((e) => {
                this.openGlobalDialog({
                    name: 'share',
                });
            });
        },

        // 去登陆
        gotoLogin(){
            this.close();
            this.$store.dispatch('global-dialog/showLogin');
        },
        //去使用
        goUse(val) {
            window.open(val.weburl)
        },
    },
    mounted() {

    },
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    $themeColor: #4b1efb;

    .dialog-sign {
        width: 780px;
        background: #eef1f3;
        border-radius: 6px;
        position: fixed;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        overflow: hidden;

        .head{
            position: relative;

            .banner{
                height: 252px;
                background-image: url(/images/daily-events/signin-bg.png);
                background-size: cover;

                .text{
                    width: 20em;
                    box-sizing: content-box;
                    color: white;
                    font-size: 22px;
                    padding: 30px 0;
                    font-weight: bold;
                    text-align: center;
                    margin: 0 auto;
                    line-height: 2em;
                }
            }

            .btn-close{
                position: absolute;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                right: 20px;
                top: 24px;
                box-sizing: border-box;
                cursor: pointer;

                &:before, &:after{
                    content: '';
                    display: bolck;
                    width: 2px;
                    height: 18px;
                    background: white;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    border-radius: 1px;
                }

                &:before {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }

                &:after{
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &:hover:before, &:hover:after{
                    background: #050070;
                }
            }
        }

        .body{
            overflow: hidden;
            padding: 20px 16px;
            margin: 0 22px;
            margin-top: -80px;
            background: white;
            border-radius: 4px;

            .item{
                padding: 18px 34px;
                box-sizing: border-box;
                line-height: 1em;
                border-radius: 4px;
                position: relative;
                background: #efeef7;

                .title{
                    overflow: hidden;

                    .index{
                        float: left;
                        font-size: 18px;
                        color: white;
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                        text-align: center;
                        border-radius: 2px;
                        background-color: $themeColor;
                        margin-right: 12px;
                    }

                    .text{
                        float: left;
                        font-size: 18px;
                        color: $themeColor;
                        line-height: 24px;
                        font-weight: bold;
                    }
                        .bar-text{
                            float: left;
                            font-size: 16px;
                            font-weight: bold;
                            margin: 6px 0 0 10px;
                            color: $warningColor;
                        }

                    .bar{
                        float: left;
                        overflow: hidden;
                        margin-top: 5px;
                        margin-left: 18px;

                        .process{
                            float: left;
                            width: 240px;
                            margin-top: 4px;
                        }

                        .process-text{
                            float: left;
                            margin-left: 8px;
                            font-size: 14px;
                            color: #303030;
                        }
                    }
                }

                .desc{
                    font-size: 16px;
                    color: #303030;
                    margin-top: 12px;
                    line-height: 1.5em;

                    .desc-item{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 30px;

                        .title {
                            span{
                                color: $themeColor;
                            }
                        }

                        .use-btn{
                            color: $themeColor;
                            border: 1px solid $themeColor;
                            background: #d3c9ff;
                            cursor: pointer;
                            padding: 0 1em;
                            font-size: 14px;
                            line-height: 22px;
                            border-radius: 11px;
                            transition: all .3s;

                            &:hover{
                                background: $themeColor;
                                color: white;
                            }
                        }
                    }
                }

                .icon-get{
                    display: none;
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    right: 6px;
                    top: 4px;
                }

                &.active{
                    border: 1px solid $themeColor;
                    // background-image: linear-gradient(269deg, #8fa9ff 0%, #f6faff 100%);
                    background: #e2dcff;


                    .icon-get{
                        display: block;
                    }
                }

                &.disabled{
                    border: solid 1px #a7afbf;

                    .title{
                        color: #898c93;

                        .index{
                            background-color: #898c93;
                        }

                        .text{
                            color: #898c93;
                        }
                    }

                    .desc{
                        color: #898c93;
                    }
                }

                &.full{
                    .title{
                        .text{
                            max-width: 310px;
                        }
                    }
                }

                & + .item{
                    margin-top: 15px;
                }
            }

            .tip-desc {
                font-size: 16px;
                margin-top: 18px;
                color: #3f3f3f;
            }
        }

        .footer{
            margin-top: 20px;
            padding: 0 22px 48px;

            .row{
                .text{
                    display: inline-block;
                    color: #2b2b2b;
                    font-size: 16px;
                    font-weight: bold;
                    margin-right: 14px;
                }

                & + .row{
					margin-top: 16px;
				}
            }

            .no-login{
                text-align: center;
            }
        }
    }
</style>