<template>
    <div class="client-btns">
        <div 
        class="btn-item minisize" 
        @click="minisizeWin"
        >
            <icon-mini></icon-mini>
        </div>

        <div 
        class="btn-item" 
        @click="triggerSize"
        >
            <icon-max v-if="!isMax"></icon-max>
            <icon-resize v-if="isMax"></icon-resize>
        </div>

        <div 
        class="btn-item close"
        @click="closeWin"
        >
            <icon-close></icon-close>
        </div>
    </div>
</template>

<script>
import beforePageLeave from '@js/beforePageLeave.js';

import IconMini from './icon/minisize.vue';
import IconClose from './icon/close.vue';
import IconMax from './icon/maxsize.vue';
import IconResize from './icon/resize.vue';

export default {
    components: {
        IconMini,
        IconClose,
        IconMax,
        IconResize,
    },
    data() {
        return {
            isMax: false,
        };
    },
    methods: {
        minisizeWin() {
            window.remote.getCurrentWindow().minimize();
        },
        triggerSize() {
            const win = window.remote.getCurrentWindow(),
                state = win.isMaximized();

            if (state) {
                win.restore();
            } else {
                win.maximize();
            }
        },
        closeWin() {
            // 先调用页面关闭前方法，再关闭页面
            beforePageLeave.dispatch(() => {
                window.remote.getCurrentWindow().close();
                // todo: 最小化、最大化、关闭窗口的方法调整
            });
        },
    },
    mounted() {
        if (!window.ipcRenderer) {
            return;
        }

        window.ipcRenderer.on('maximize', (e) => {
            this.isMax = true;
        });

        window.ipcRenderer.on('unmaximize', (e) => {
            this.isMax = false;
        });
    },
};
</script>

<style lang="scss" scoped>
    .client-btns{
        display: flex;
        height: 100%;

        .btn-item{
            display: flex;
            align-items: center;
            padding: 0 .7em;
            transition: all .1s;
            font-size: 14px;
            cursor: pointer;

            &:hover{
                color: white;
                background: rgba(0, 0, 0, .1);
            }

            &.close:hover{
                background: red;
            }
        }
    }
</style>