const PROXY_MAP = {
    development: {
        // tbez: 'http://www.huitoue.com',
        tbez: 'http://192.168.1.7:9083',
        // tbez: 'http://192.168.1.47:9083',
        // tbez: 'http://192.168.1.104:9083',
        // standalonfile: 'http://192.168.1.100:12036',
        // standalonfile: 'http://192.168.1.47:9084',
        // fileCenter: 'http://192.168.1.104:12035',
        standalonfile: 'http://www.huitoue.com',
        fileCenter: 'http://www.huitoue.com',

        // tbez: 'http://192.168.1.100:12016',
        // standalonfile: 'http://192.168.1.100:12016',
        // fileCenter: 'http://192.168.1.100:12016',
    },
    production: {
        tbez: 'http://127.0.0.1:9083',
        standalonfile: 'http://127.0.0.1:9084',
        fileCenter: 'http://127.0.0.1:12065',
    }
};

const PROXY_ENV = PROXY_MAP[process.env.NODE_ENV];

export default {
    plugins: {
        element: true,
        myComs: true,
        vuexStorage: false,
        jquery: true,
        sentry: false,
        cnzz: {
            id: '1281004376',
        },
    },
    proxy: {
        // 云盘的上传也走的这个代理，所以本地的云盘会上传到非100
        '/tbez': {
            target: PROXY_ENV.tbez,
            changeOrigin: true,
            pathRewrite: {
                '^/': '/'
            }
        },
        '/upload/document': {
            target: PROXY_ENV.tbez,
            changeOrigin: true,
            pathRewrite: {
                '^/': '/'
            }
        },
        // 代理至上传资源文件夹，网盘用到
        '/upload': {
            target: PROXY_ENV.standalonfile,
            changeOrigin: true,
            pathRewrite: {
                '^/': '/'
            }
        },
        // 代理至文件库，文件分类取自接口
        '/file': {
            target: PROXY_ENV.fileCenter,
            changeOrigin: true,
            pathRewrite: {
                '^/': '/'
            }
        },
        // 附件系统
        '/standalonfile': {
            target: PROXY_ENV.standalonfile,
            changeOrigin: true,
            pathRewrite: {
                '^/': '/'
            }
        },
        // 畅写
        '/changxie': {
            target: 'http://124.239.148.244:8081',
            changeOrigin: true,
            pathRewrite: {
                '^/changxie': '/'
            }
        },
        '/doc/': {
            target: 'http://124.239.148.244:8081',
            changeOrigin: true,
			ws: true,
            pathRewrite: {
                '^/': '/'
            }
        },
		'/cache/files': {
            target: 'http://124.239.148.244:8081',
            changeOrigin: true,
            pathRewrite: {
                '^/': '/'
            }
        },

        '/localupload': {
            target: PROXY_ENV.standalonfile,
            changeOrigin: true,
            pathRewrite: {
                '^/localupload': '/'
            }
        },

        '/proxy/out/search/suggest.json': {
            target: 'https://capi.tianyancha.com/cloud-tempest/search/suggest/v5',
            changeOrigin: true,
            pathRewrite: {
                '^/proxy/out/search/suggest.json': ''
            }
        },
    },
}
