import Vue from 'vue';
import storage from '@js/storage';

var userinfo = {};

// 这段没有意义，貌似nuxt在服务端就会生成state实例，并传给客户端，新的值不会覆盖已生成实例中的值
if (process.browser) {
    userinfo = storage.getSession('userinfo') || {};
}

export const state = () => ({
    data: userinfo,

    dialogBindMobileVisible: false, //绑定手机号弹窗

    vipconfig: {
        '1': {
            name: '尊享会员',
            icon: require('@images/icon/icon-Cu.png'),
        },
        '2': {
            name: '乐享会员',
            icon: require('@images/icon/icon-Ag.png'),
        },
        '3': {
            name: '畅享会员',
            icon: require('@images/icon/icon-Au.png'),
        },
    }
});

export const mutations = {
    updateData(state, n) {
        state.data = n;
        storage.setSession({
            userinfo: n,
        });
    },
    updateDialogBindMobileVisible(state, n) {
        state.dialogBindMobileVisible = n;
    },
};

export const getters = {
    getData(state) {
        return state.data;
    },

    // 用户昵称
    getNickname(state, getters) {
        // console.log(getters.getData.mobile);
        return getters.getData.mobile;
    },
    //用户头像
    getHeadportraitpath(state, getters) {
        return getters.getData.headportraitpath;
    },
    getType(state, getters) {
        // P: 普通用户

        return getters.getData.type;
    },
    getLevel(state, getters) {
        // 有值则为vip
        return getters.getData.viplevel;
    },
    getDialogBindMobileVisible(state, getters) {
        return state.dialogBindMobileVisible;
    },
    getUserVipInfo(state, getters) {
        var level = getters.getLevel;

        if (!level) {
            return {}
        }

        return state.vipconfig[level];
    },
    // 尚未补充信息
    getNeedToCompleteInfo(state, getters) {
        let keyList = ['dwname', 'mobile', 'position', 'prefer'];

        if (keyList.some(key => {
            return !getters.getData[key];
        })) {
            return true;
        }

        return false;
    },
};

export const actions = {
    /**
     * 请求用户身份信息，仅在有SERIAL_CODE时请求
     * @param {Object} state state实例
     * @param {Object} obj 参数
     * @returns 
     */
    queryIsSearch(){
        // 如果客户端,
        if (!!window.electronApi) {
            state.commit('updateIsSearch', data);
        }
    },


    queryUserInfo(state, obj = {requery: false}) {
        const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');

        if (!SERIAL_CODE) return;

        var query = () => {
            Vue.prototype.$get(`${this.state.api.webUrl}/userlogin/userinfo.json`, data => {
                state.commit('updateData', data);

                // 如果客户端
                if (window.electronApi) {
                    //data为用户信息
                    window.electronApi.dispatch('login', data);
                }
            });
        };

        if (obj.requery) {
            query();
        } else {
            if (state.getters.getNickname) return;

            query();
        }
    },
    /**
     * 验证码登录
     * @param {Object}} state state实例
     * @param {Object} obj 传入的参数，包含data，success
     */
    smsloginHandler(state, {data, success, complete, error}) {
        var ssc = storage.getLocal('ssc'),
            ssb = storage.getLocal('ssb'),
            clientmac = ''; // 客户端mac

        if (process.browser && window.electronApi) {
            // 客户端环境
            clientmac = window.electronApi.mac;
        }

        Vue.prototype.$ajax({
            type: 'post',
            url: `${this.state.api.webUrl}/userlogin/smslogin.json`,
            data: {
                ...data,
                laiyuanuser: ssc || '',
                laiyuanweb: ssb || '',
                clientmac,
            },
            callback: data => {
                state.commit('updateData', data);

                var shbj = data.shbj;

                // if (shbj !== '1') {
                //     // 注册并首次登陆
                //     this.dispatch('global-dialog/openGlobalDialog', {
                //         name: 'supplement',
                //     });
                // }
                
                // 如果客户端
                if (window.electronApi) {
                    //data为用户信息
                    window.electronApi.dispatch('login', data);
                }
                
                success.call(this, data);
            },
            complete() {
                complete && complete.call(this)
            },
            error() {
                error && error.call(this);
            }
        });
    },
    /**
     * 通过微信扫码，登录系统
     * @param {Object} state store实例
     * @param {Object} param1 参数对象
     */
    wxLoginHandler(state, {wxdata = {code: '', state: '',}, success, notbind}) {
        var ssc = storage.getLocal('ssc'),
            ssb = storage.getLocal('ssb'),
            clientmac = ''; // 客户端mac

        if (process.browser && window.electronApi) {
            // 客户端环境
            clientmac = window.electronApi.mac;
        }

        Vue.prototype.$post(`${this.state.api.webUrl}/userlogin/callback.json`, {
            ...wxdata,
            laiyuanuser: ssc || '',
            laiyuanweb: ssb || '',
            clientmac,
        }, data => {
            let userData = data.userinfo;

            // isbind == '0' 未绑定 。
            if(data.isbind === '0'){
                notbind(data.bindinfo);
            } else{
                state.commit('updateData', userData);

                // 如果客户端
                if (window.electronApi) {
                    //data为用户信息
                    window.electronApi.dispatch('login', data);
                }
                
                success(userData);
            }
        });
    },
    /**
     * 退出登录，成功后清空用户信息缓存
     * @param {Object} state state实例
     * @returns 
     */
    logoutHandler(state, cb) {
        const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');
        // debugger

        if (!SERIAL_CODE) {
            //没有cookies 有 userinfo ，清除userinfo
            if(state.getters.getNickname ){
                console.log('没有cookies 有 userinfo');
                state.commit('updateData', {});
            }
            return;
        } 

        Vue.prototype.$post(`${this.state.api.webUrl}/userlogin/logout.json`, data => {
            state.commit('updateData', {});

            this.dispatch('message/clearMsgCenter');

            this.$cookies.remove('SERIAL_CODE');

            // 如果客户端
            if (window.electronApi) {
                //data为用户信息
                window.electronApi.dispatch('logout');
            }

            if (cb) {
                cb();
                return;
            }

            if (/^(\/user)/.test(window.location.pathname)) {
                window.location.replace('/home');

                return;
            }
            // debugger
            window.location.reload();
        });
    },
    nuxtServerInit({commit}, {req}) {
        // console.log(req.headers.cookie);
    },
    
    showBindMobile({commit}, cb) {      // showLogin
        commit('updateDialogBindMobileVisible', true);
    },

    /**
     * 补充用户信息
     */
    adjustUserInfo(state, {data, success, complete, error}){
        Vue.prototype.$ajax({
            type: 'get',
            url: `${this.state.api.webUrl}/userlogin/adjustuserinfo.json`,
            data: {
                ...data,
            },
            callback: data => {
                state.dispatch('queryUserInfo', {requery: true});

                success.call(this, data);
            },
            complete() {
                complete && complete.call(this)
            },
            error() {
                error && error.call(this);
            }
        });
    },
    checkIsLogin(state, callback) {
        const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');	

        if (SERIAL_CODE) {
            // 已登录

            callback && callback();
        } else {
            // 未登录

            this.dispatch('global-dialog/showLogin', () => {
                callback && callback();
            });
        }
    },

    // 是否登录
    checkIsLoginNoDoing(state, {login, noLogin}){
        const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');
        if (SERIAL_CODE) {
            login && login();
        } else {
            noLogin && noLogin();
        }
    },
};