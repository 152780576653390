// 广告用store

import Vue from 'vue';
import storage from '@js/storage';

export const state = () => ({
    map: {
        homeA: '100',
        homeB: '101',
        videoA: '104',
        videoB: '105',
        qaspecial: '102',
    },
    data: {
        
    },
    queryed: false,
});

export const mutations = {
    updateData(state, n) {
        state.data = n;

        // storage.setSession({
        //     ADS_DATA: n,
        // });
    },
    updateQueryed(state, n) {
        state.queryed = n;
    },
};

export const getters = {
    getData(state) {
        var data = {};

        if(process.browser) {
            data = storage.getSession('ADS_DATA') || state.data;
        }

        return data;
    },
    getItem(state, getters) {
        return (type) => {
            return (getters.getData[state.map[type]] || []);
        };
    },
};

export const actions = {
    queryData(store) {
        // var data = store.getters.getData;

        // if (Object.keys(data).length) {
        //     return;
        // }

        if (store.state.queryed) return;

        store.commit('updateQueryed', true);

        Vue.prototype.$get(`${this.state.api.webUrl}/admessage/list.json`, data => {
            store.commit('updateData', data);
        });
    },
};