<template>
    <default-layout>
        <div class="layout-user-center">

            <div class="content">
                <div 
                class="top" 
                ref="top-nav"
                >
                    <user-nav></user-nav>

                    <slot name="top"></slot>
                </div>
                <div class="bottom">
                    <nuxt></nuxt>
                </div>
            </div>
        </div>

        <dialog-product></dialog-product>

        <my-footer v-if="footerShow"></my-footer>
    </default-layout>
</template>

<script>
import MyFooter from '@components/sys/my-footer.vue';
import DefaultLayout from './client';
import UserNav from '../pages/user/center/components/nav.vue';
import DialogProduct from '@/pages/user/center/components/dialog-product.vue';

export default {
    components: {
        MyFooter,
        DefaultLayout,
        UserNav,
        DialogProduct,
    },
    props: {
        
    },
    data() {
        return {
            footerShow: false,
        };
    },
    methods: {
        
    },
    mounted() {
        if (!window.electronApi) {
            this.footerShow = true;
        }
    },
};
</script>

<style lang="scss" scoped>
    .layout-user-center{
        overflow: hidden;
        margin-top: -20px;

        & > .content {
            margin: 20px auto;
        }

        .top {
            margin: 12px 0;
        }
        .bottom {
            // height: 2000px;
        }

    }
</style>