<template>
    <dialog-custom 
        v-model="isShow" 
        theme="share" 
        context="好友注册成功，双方均可获得5次投标方案模版下载" 
        context-top="153"
        tips="已复制分享信息，请粘贴给好友，邀请好友登录投标E站"
        btn="确定" 
    ></dialog-custom>
</template>

<script>
import {mapActions} from 'vuex';

import DialogCustom from './dialog-custom.vue';

export default {
    components: {
        DialogCustom,
    },
    data() {
        return {
            
        };
    },
    computed: {
        isShow: {
            get() {
                return this.$store.getters['global-dialog/getGlobalDialogVisible']('share');
            },
            set(val) {
                this.$store.commit('global-dialog/updateDialogVisible', {
                    name: 'share',
                    visible: val,
                });
            },
        },
    },
    methods: {
        ...mapActions({
            openGlobalDialog: 'global-dialog/openGlobalDialog',
            closeGlobalDialog: 'global-dialog/closeGlobalDialog',
        }),
    }
};
</script>