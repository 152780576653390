var handler = null;

export default {
    /**
     * 注册事件
     * @param {Function} cb 回调事件
     */
    add(cb) {
        handler = cb;
    },
    /**
     * 移除事件
     */
    remove() {
        handler = null;
    },
    /**
     * 运行注册的事件
     * @param {Function} next 运行完毕注册事件后的回调
     * @returns 
     */
    dispatch(next) {
        if (!handler) {
            next();
            return;
        }

        handler(next);
    }
}