// 用户手头服务次数相关

import Vue from 'vue';
import app from '@js/app-node.js';

export const state = () => ({
    statisticData:[],
});

export const mutations = {
    updateStatisticData(state, n) {
        state.statisticData = n;
    },
};

export const getters = {
    // 统计信息
    getStatisticData(state){
        return state.statisticData;
    },
    // 获取服务次数项
    getStatisticDataItem: (state) => (search = {
        key: 'name', 
        value: '', 
        target: 'surplus', 
        def: 0
    }) => {
        var statisticData = state.statisticData || [],
            {key, value, target, def} = search;

        let arr = statisticData.filter(item => item[key] === value);
        
        if (arr.length > 0){
            if (!target) {
                return arr[0];
            }
            
            return arr[0][target];
        } else {
            if (!target) {
                return {};
            }
            
            return def;
        }
    },
};


export const actions = {
    /**
     * 获取当前登录用户的使用次数信息
     * @param {Object} state state对象
     * @param {Function} cb 请求成功回调
     * @returns 
     */
    queryStatisticData(state, cb){
        const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');

        if (!SERIAL_CODE) return;

        Vue.prototype.$get(`${this.state.api.tbUrl}/center/list.json`, data => {
            state.commit('updateStatisticData', data);
                
            cb && cb(data);
        });
    },

    /**
     * 检查是否有权限、使用次数并执行相应动作
     *  是否登录
     *  判断是否有查看权限，
     *      有 -》 就显示。
     *      无 -》 有次数-消耗次数
     *          - 无次数- 购买次数
     * @param {Object} state state对象
     * @param {Object} param1 配置项
     * @returns 
     */
    userPowerLook(state, {
        typeName, 
        data, 
        haveLogin, // 已登录
        noLogin, // 没登录
        havePower,  // 有权限
        noPower, // 没权限
        haveNums, // 有次数
        noNums, // 没次数
        noPowerHaveNums, // 没权限，有次数
        noPowerNoNums, // 没权限，没次数
        whenNoNumShowDialog = false, // 没次数时自动提示购买
        cb, // 所有权限查询完毕
    }) {
        var that = Vue.prototype;

        var power = false;

        new app.Chain().link(next => {
            // 校验登录
            const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');	

            if (!SERIAL_CODE) {
                noLogin && noLogin();

                cb && cb(); // 没登录，提前结束
                return;
            }

            haveLogin && haveLogin();

            next();
        }).link(next => {
            //获取已有权限
            that.$get(`${this.state.api.tbUrl}/consume/ispermission.json`, data , resData =>{
                if(resData.ispermission) {
                    //有权限 直接查看
                    power = true;

                    havePower && havePower();
                } else {
                    noPower && noPower();
                }
                
                next();
            });
        }).link(next => {
            // 检查是否有剩余次数
            that.$get(`${this.state.api.tbUrl}/consume/getsurplusnum.json`, data , resData =>{
                if(!resData.count || resData.count === '0') {
                    // 无剩余次数

                    if(whenNoNumShowDialog){
                        // 要求展示弹框

                        // `${typeName} 剩余使用次数为0，点击确定去购买次数`
                        that.$confirm('今日免费次数已用完，明日再来或者直接购买次数。', '提示', {
                            confirmButtonText: '去购买',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            noNums && noNums(resData.count);

                            if (!power) {
                                noPowerNoNums && noPowerNoNums(resData.count);
                            }
                        }).catch(() => {});
                    } else{
                        // 不要求展示弹框
                        
                        noNums && noNums(resData.count);

                        if (!power) {
                            noPowerNoNums && noPowerNoNums(resData.count);
                        }
                    }
                    
                } else {
                    // 有剩余次数

                    haveNums && haveNums(resData.count);

                    if (!power) {
                        noPowerNoNums && noPowerHaveNums(resData.count);
                    }
                }

                next();
            })
        }).link(next => {
            cb && cb(); // 全部查询完毕，结束
        }).run();
    },
    
    //使用次数
    userUsedNums(state, { data, success,}){
        this.dispatch('userinfo/checkIsLogin',() => {
            Vue.prototype.$post(`${this.state.api.tbUrl}/consume/use.json`, data , data =>{
                success && success(data);
            });
        });
    },
};