// 百度统计
if(process.browser) {
    try {
        window._hmt = window._hmt || [];

        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?d78ab343f36ba2d06ba9e182b569b971";
        var s = document.getElementsByTagName("script")[0]; 
        s.parentNode.insertBefore(hm, s);
    } catch (e) {
        // e
    }
}