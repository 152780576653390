<template>
    <my-dialog 
    class="dialog-clause"
    v-model="model" 
    :title="title"
    :width="width"
    :top="top"
    >
        <div 
            ref="html"
            class="clause-html" 
            :style="{height: height}"
            v-html="content"
            @scroll="scrollHandler"
        ></div>

        <div class="btn" v-if="agree">
            <el-button 
            type="primary" 
            :disabled="btnDisabled"
            @click="agreeHandler"
            >
                同意并继续
            </el-button>
        </div>
    </my-dialog>
</template>

<script>
import MODEL_MIXIN from '@mixins/model';

export default {
    mixins: [MODEL_MIXIN],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        agree: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: '50%',
        },
        height: {
            type: String,
            default: '600px',
        },
        top: {
            type: String,
            default: '15vh',
        },
    },
    data() {
        return {
            btnDisabled: true,

            content: ``
        };
    },
    watch: {
        model(val) {
            if (!val) return;

            this.$nextTick(() => {
                this.scrollHandler({
                    target: this.$refs.html,
                });
            });
        },
    },
    methods: {
        scrollHandler(e) {
            if (!this.agree) {
                return;
            }

            var target = e.target,
                delta = 5;

            if ((target.scrollTop + target.clientHeight + delta) >= target.scrollHeight) {
                this.btnDisabled = false;
            } else {
                this.btnDisabled = true;
            }
        },
        agreeHandler() {
            this.$emit('click');
        },
        queryData() {
            if (!this.type) return;

            this.$get(`${this.$store.state.api.webUrl}/infomation/detail.json`, {
                type: this.type,
            }, data => {
                this.content = data.content;
            });
        },
    },
    mounted() {
        this.queryData();
    },
};
</script>

<style scoped lang="scss">
    .dialog-clause{
        ::v-deep{
            .el-dialog__header{
                background: #F4F4F4;
            }
        }
        
        .clause-html {
            overflow: auto;
        }

        .btn{
            text-align: center;
        }
    }
</style>