<template>
    <div>
        <div 
        class="btn-share" 
        @click="clickHandler"
        v-czc="['导航栏', '点击按钮' , '分享' ,0 , `nav-btn-share`]"
        >
            <i class="el-icon-share"></i>
            <div class="text">
                分享
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CONFIG_PROJECT from '@config/project';

export default {
    components: { },
    data() {
        return {
            
        };
    },
    computed: {
        ...mapGetters({
            userNickname: 'userinfo/getNickname',
        }),
    },
    methods: {
        ...mapActions({
            openGlobalDialog: 'global-dialog/openGlobalDialog',
        }),
        clickHandler() {
            var url = `您的好友邀请您登录${CONFIG_PROJECT.title}，领取五次投标方案模板下载。地址:${CONFIG_PROJECT.origins.www}`;

            if (this.userNickname) {
                // 已登录则带上账号信息
                url += `?ssc=${this.userNickname}`;
            }

            // if (window.electronApi) {
            //     // 客户端则复制下载地址
            //     url = this.getClientDownloadUrl();
            // }

            this.$copyText(url).then((e) => {                
                this.openGlobalDialog({
                    name: 'share',
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-share {
    display: flex;
    color: white;
    cursor: pointer;
    border: 1px solid white;
    border-radius: 20px;
    padding: 4px 6px;
    transition: all 0.3s;
    line-height: 1em;
    font-size: 14px;
    margin-left: 8px;

    &:hover {
        background-color: #6689ff;
    }
}

@media screen and (max-width: 1400px) {
    .btn-share {
        padding: 4px;

        .text {
            display: none;
        }
    }
}
</style>