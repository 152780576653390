<template>
    <el-input 
    class="input-yzm"
    :placeholder="placeholder"
    v-model="model" 
    >
        <btn-yzm
            slot="append" 
            class="btn-yzm"
            :mobile="mobile"
        ></btn-yzm>
    </el-input>
</template>

<script>
import MODEL from '@mixins/model';

import BtnYzm from './btn-yzm';

export default {
    mixins: [ MODEL ],
    components: {
        BtnYzm,
    },
    props: {
        mobile: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .input-yzm{
        overflow: hidden;

        .col-input{
            float: left;
        }

        .col-btn{
            float: right;
        }

        ::v-deep {
            .el-input-group__append{
                background-color: white;
                color: $primaryColor;
                transition: all .3s;
                border-radius: 4px;

                &:hover{
                    background-color: $primaryColor;
                    color: white;
                }
            }
        }
    }
</style>