<template>
    <my-dialog 
    class="dialog-product" 
    v-model="model" 
    title="请选择购买的产品" 
    width="700px" 
    @close="close"
    >
        <div class="product-items">
            <div 
            v-for="item in dataList" 
            :key="item.name" 
            class="item" 
            :class="isSelected(item) ? 'active' : ''" 
            @click="handleClick(item)"
            >
                <div class="item-name">
                    {{item.name}}
                </div>
                <div>
                    {{`${item.count}${item.counttypename || ''}`}}
                </div>
                <div>
                    ￥&nbsp;{{`${toDecimal2(item.realamount)}`}}
                </div>
            </div>
        </div>

        <div class="btn">
            <div class="row">
                <el-button type="warning" @click="payHandler">立即购买</el-button>
            </div>

            <div class="row">
                <el-checkbox v-model="clauseChecl">我已阅读并接受</el-checkbox>
                <span class="btn-clause" @click="dialogVisible = true">
                    《用户协议及隐私政策》
                </span>
            </div>
        </div>

        <dialog-clause 
            v-model="dialogVisible" 
            title="用户协议及隐私政策" 
            type="login"
        ></dialog-clause>

        <dialog-pay 
            ref="payer" 
            @success="paySuccessHandler"
        ></dialog-pay>
    </my-dialog>
</template>

<script>
import DialogClause from '@components/sys/dialog-clause';
import DialogPay from '../../../member/components/dialog-pay.vue';
import MATH_MIXIN from '../mixins/math';

import { mapActions } from 'vuex';

export default {
    mixins: [MATH_MIXIN],
    components: {
        DialogClause,
        DialogPay,
    },
    data() {
        return {
            form: {
                realamount: 0,
                productnum: 1,
                projectnames: '',
                projectids: '',
            },
            clauseChecl: false,
            dialogVisible: false,
            selected: [],
            dataList: [],
        };
    },
    watch: {
        projectids: {
            handler(n, o) {
                if(n && n !== o) {
                    this.queryData();
                }
            },
            immediate: true,
        },
    },
    computed: {
        model: {
            get() {
                return this.$store.getters['global-dialog/getGlobalDialogVisible']('product');
            },
            set(val) {
                this.$store.commit('global-dialog/updateDialogVisible', {
                    name: 'product',
                    visible: val,
                });
            },
        },
        projectids() {
            return this.$store.state['global-dialog'].product.props.projectids;
        },
    },
    methods: {
        ...mapActions({
            showLogin: 'global-dialog/showLogin',
            queryUserInfo: 'userinfo/queryUserInfo',
            callbackGlobalDialog: 'global-dialog/callbackGlobalDialog',
        }),
        handleClick(item) {
            let res = this.selected.includes(item.name);

            if (!res) {
                this.selected = [...this.selected, item.name];
            } else {
                this.selected = this.selected.filter((item1) => item1 != item.name);
            }

            if (this.selected && this.selected.length > 0) {
                let arr = [];

                arr = this.dataList.filter((item) => this.selected.includes(item.name));

                this.form.realamount = arr.map((item) => item.realamount).reduce((total, num) => this.accAdd(total, num));
                this.form.projectnames = arr.map((item) => item.name).toString();
                this.form.projectids = arr.map((item) => item.rowguid).toString();
            } else {
                this.form = {
                    realamount: 0,
                    productnum: 1,
                    projectnames: '',
                    projectids: '',
                };
            }
        },
        isSelected(item) {
            let res = this.selected.includes(item.name);

            return res;
        },
        payHandler() {
            const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');

            new Chain()
                .link((next) => {
                    if (!this.selected || this.selected.length <= 0) {
                        showMsgBox('请选择要购买的产品');

                        return true;
                    }

                    next();
                })
                .link((next) => {
                    if (!SERIAL_CODE) {
                        this.showLogin(() => {
                            this.payHandler();
                        });

                        return true;
                    }

                    next();
                })
                .link((next) => {
                    if (!this.clauseChecl) {
                        showMsgBox('请阅读并接受《用户协议及隐私政策》');
                        return true;
                    }

                    next();
                })
                .link((next) => {
                    // 调起支付

                    this.$refs.payer.initHandler({...this.form , projecttype : 'basic'});
                })
                .run();
        },
        paySuccessHandler() {
            this.model = false;
            this.queryUserInfo({ requery: true });

            this.$emit('success');
        },
        queryData() {
            this.$get(`${this.$store.state.api.tbUrl}/center/buylist.json`, data => {
                this.dataList = data;

                if (!this.projectids) {
                    return;
                }

                // 转换成id数组
                let idsArr = this.projectids.split(',');

                // 根据id筛选出已选中的数据
                let arr = data.filter((item) => idsArr.includes(item.rowguid));
            
                if(!arr.length) { 
                    return 
                }

                this.selected = arr.map((item) => item.name);

                this.form.realamount = arr.map((item) => item.realamount).reduce((total, num) => this.accAdd(total, num));
                this.form.projectnames = arr.map((item) => item.name).toString();
                this.form.projectids = arr.map((item) => item.rowguid).toString();
                
            });
        },
        close() {
            this.queryData();

            this.callbackGlobalDialog('product/onclose');
        },
    },
    mounted() {
        
    },
};
</script>

<style scoped lang="scss">
@import '@css/var.scss';

.dialog-product {
    .product-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
            width: 294px;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #e6e6e6;
            padding: 10px;
            padding-right: 17px;
            margin-top: 10px;
            font-weight: 600;
            cursor: pointer;
            overflow: hidden;
            transition: all 0.3s;

            &.active,
            &:hover {
                border: solid 1px $primaryColor;
                color: $primaryColor;
                background-color: #edf1ff;
                position: relative;
            }

            &.active:before {
                content: '';
                position: absolute;
                display: block;
                width: 35px;
                height: 35px;
                background: $primaryColor;
                right: -18px;
                top: -18px;
                transform: rotate(-45deg);
            }

            &.active:after {
                content: '√';
                position: absolute;
                display: block;
                right: 2px;
                top: -2px;
                color: white;
                font-size: 13px;
            }

            .item-name {
                width: 90px;
            }
        }
    }

    .btn {
        text-align: center;
        margin: 60px 0 0;

        .row {
            & + .row {
                margin-top: 18px;
            }
        }

        .btn-clause {
            color: $primaryColor;
            cursor: pointer;
        }
    }
}
</style>