export const state = () => ({
    fileUrl: '/file',
    sfile: '/standalonfile',

    webUrl: `/tbez/web`,

    searchUrl: `/tbez/search`,
    sysUrl: `/tbez`,
    tbUrl: `/tbez/tb`,
    mngUrl: `/tbez/platformmg`,
    ecUrl: `/tbez/tbez`, 
})

export const mutations = {
    
}

export const getters = {
    
}
